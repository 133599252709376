import { FC } from "react";
import { KTIcon } from "../../../../../_metronic/helpers";
import { useNavigate } from "react-router-dom";

interface SetUserCode {
  setUserCode: React.Dispatch<React.SetStateAction<string | null>>;
}

export const UserLockModalHeader: FC = () => {
  const navigate = useNavigate();

  const handlerClickHome = () => {
    navigate("/");
  };

  return (
    <div className="modal-header">
      {/* begin::Modal title */}
      <h2 className="fw-bolder mb-0">Ваш профиль заблокирован</h2>
      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className="btn btn-icon btn-sm btn-primary btn-active-icon-light"
        data-kt-users-modal-action="close"
        onClick={handlerClickHome}
        style={{ cursor: "pointer" }}
      >
        <KTIcon iconName="cross" className="fs-1" />
      </div>
      {/* end::Close */}
    </div>
  );
};

import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";

const API_URL = process.env.REACT_APP_TELEGRAM_BOT_URL;

export const TelegramLoginButton = () => {
  return (
    <>
      <a
        href={API_URL}
        target="_blank"
        className="btn btn-primary"
        style={{ borderRadius: "50px" }}
        rel="noreferrer"
      >
        <SVG
          src={toAbsoluteUrl("/media/socialIcons/telegram.svg")}
          width={20}
          title="Telegram"
          className="me-4 mb-1"
        />
        Войти через Telegram
      </a>
    </>
  );
};

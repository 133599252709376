import clsx from 'clsx'
import { format } from 'date-fns'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ModalLayout } from '../../../../_metronic/partials/modals/ModalLayout'
import { convertCentsToDollars, convertDollarsToCents } from '../../../utils'
import { useAuth } from '../../auth'
import { BadgeStatusDispute } from '../../components/BadgeStatusDispute'
import { LoadingRingCard } from '../../components/LoadingRingCard'
import { ToastTopLeft } from '../../components/ToastTopLeft'
import { ShowError } from '../../errors/components/ShowError'
import { Deal } from '../core/_models'
import {
	useAcceptDispute,
	useDisputePrice,
	useDisputeResolve,
	useGetDeal,
	useGetDealHistory,
	useGetDispute,
	useSendMessage,
} from '../core/_requests'
import { DepositDetails, DisputeData } from '../dispute-management/core/_models'
import { BadgeReadyCompleteFool } from './components/BadgeReadyCompleteFool'
import { BadgeReadyComplete } from './components/BadgeReadyСomplete'
import { DealDataCardBody } from './components/DealDataCardBody'
import { DealDataCardHeader } from './components/DealDataCardHeader'
import { ShareDealModal } from './components/share-deal-modal/ShareDealModal'

export function ArbiterCardEarly() {
	const navigate = useNavigate()
	const { currentUser } = useAuth()

	const { uuid } = useParams()

	const [modalData, setModalData] = useState<any | null>(null)
	const [isOpenShareModal, setIsOpenShareModal] = useState(false)
	const [showSuccessToast, setShowSuccessToast] = useState(false)
	const [showErrorToast, setShowErrorToast] = useState(false)

	const [deal, setDeal] = useState<Deal | undefined>(undefined)
	const [description, setDescription] = useState<any>(undefined)

	const [halfBuyerAmount, setHalfBuyerAmount] = useState(0)
	const [halfSellerAmount, setHalfSellerAmount] = useState(0)
	const [halfErrorInput, setHalfErrorInput] = useState<any>(null)
	const [foolAmount, setFoolAmount] = useState<any>(0)
	const [sendLinkInput, setSendLinkInput] = useState('')

	const [error, setError] = useState<any | null>(null)

	const prevDealDataRef = useRef<any>(null)
	const [disputeDataCurrent, setDisputeDataCurrent] =
		useState<DisputeData | null>(null)

	const {
		mutate: getDeal,
		data: dealData,
		isLoading: isDataDealLoading,
		error: dealDataError,
	} = useGetDeal()

	const { mutate: getDispute, data: disputeData } = useGetDispute()

	const {
		mutate: acceptDispute,
		data: acceptDisputeData,
		isLoading: isAcceptDisputeLoading,
		error: acceptDisputeError,
	} = useAcceptDispute()

	const {
		mutate: disputePrice,
		data: disputePriceData,
		isLoading: isDisputePriceLoading,
		error: disputePriceError,
	} = useDisputePrice()

	const {
		mutate: disputeResolve,
		data: disputeResolveData,
		isLoading: isDisputeResolveLoading,
		error: disputeResolveError,
	} = useDisputeResolve()

	const { mutate: getHistory, data: history } = useGetDealHistory()

	const { mutate: sendMessage, isLoading: isSendMessageLoading } =
		useSendMessage()

	const sendLinksUsers = () => {
		sendMessage({
			uuid: deal?.seller.uuid,
			sendData: {
				message: sendLinkInput,
			},
		})

		sendMessage({
			uuid: deal?.buyer.uuid,
			sendData: {
				message: sendLinkInput,
			},
		})
	}

	useEffect(() => {
		if (!uuid) {
			navigate('/')
		} else {
			setDeal(undefined)
			getDeal(uuid)

			getDeal(uuid, {
				onSuccess: data => {
					if (data.dispute) {
						getDispute(uuid)
					}
				},
			})

			const intervalId = setInterval(() => {
				getDeal(uuid, {
					onSuccess: data => {
						if (data.dispute) {
							getDispute(uuid)
						}
					},
				})
			}, 15000)

			// Очистка интервала при размонтировании компонента или смене uuid
			return () => clearInterval(intervalId)
		}
		setError(null)
	}, [uuid])

	useEffect(() => {
		if (
			dealData &&
			JSON.stringify(dealData) !== JSON.stringify(prevDealDataRef.current)
		) {
			prevDealDataRef.current = dealData
			setDeal(dealData)
			parseObjectFromString(dealData?.description)
			getHistory(uuid as string)
		}
	}, [dealData])

	useEffect(() => {
		if (
			disputeData &&
			JSON.stringify(disputeData) !== JSON.stringify(disputeDataCurrent)
		) {
			setDisputeDataCurrent(disputeData)
		}
	}, [disputeData, disputeDataCurrent])

	useEffect(() => {
		if (disputeDataCurrent) {
			setHalfSellerAmount(
				convertCentsToDollars(
					disputeDataCurrent?.balance - disputeDataCurrent?.price
				)
			)
			setFoolAmount(
				convertCentsToDollars(
					disputeDataCurrent?.balance - disputeDataCurrent?.price
				)
			)
		}
	}, [disputeDataCurrent])

	useEffect(() => {
		if ((disputeResolveData || acceptDisputeData) && uuid) {
			getDeal(uuid)
			getDispute(uuid)
		}
	}, [disputeResolveData, acceptDisputeData])

	useEffect(() => {
		const errors = [
			dealDataError,
			acceptDisputeError,
			disputePriceError,
			disputeResolveError,
		]

		const firstError = errors.find(error => error !== undefined)

		if (firstError) {
			setError(firstError)
			setShowErrorToast(true)
		}
	}, [
		dealDataError,
		acceptDisputeError,
		disputePriceError,
		disputeResolveError,
	])

	function parseObjectFromString(str: string) {
		const obj: any = {}

		const keyValues = str.split(' /n/ ')

		keyValues.forEach(kv => {
			let indexOfFirstColon = kv.indexOf(':')
			if (indexOfFirstColon === -1) {
				obj[kv] = '-'
			} else {
				let key = kv.substring(0, indexOfFirstColon)
				let value = kv.substring(indexOfFirstColon + 1).trim()
				obj[key] = value === 'undefined' || value === '' ? '-' : value
			}
		})

		setDescription(obj)
	}

	const getCommissionPayer = (deposit: DepositDetails): string => {
		const { seller, buyer } = deposit

		if (seller && buyer) {
			return 'Оплатил покупатель и продавец'
		} else if (seller) {
			return 'Оплатил продавец'
		} else if (buyer) {
			return 'Оплатил покупатель'
		} else {
			return 'Будет взята из обеспечения сделки'
		}
	}

	const translations: { [key: string]: string } = {
		seller: 'Продавец',
		buyer: 'Покупатель',
		moderator: 'Модератор',
	}

	const translateRole = (role: string): string => {
		return translations[role] || role
	}

	const setModalHalfDispute = () => {
		setModalData({
			title: 'Решить спор в пользу оплатившей стороны',
			desc: `Закрыть спор в пользу оплатившей стороны, комиссия за арбитраж нулевая`,
			action: () => {
				acceptDispute(uuid as string, {
					onSuccess: () => {
						disputePrice(
							{ uuid: uuid, price: 0 },
							{
								onSuccess: data => {
									const sendData = data.deposit.seller
										? { seller: data.balance, buyer: 0 }
										: { buyer: data.balance, seller: 0 }

									disputeResolve({ uuid, sendData })
								},
							}
						)
					},
				})
			},
			isLoading:
				isAcceptDisputeLoading ||
				isDisputePriceLoading ||
				isDisputeResolveLoading,
			btnTrueName: 'Подтвердить',
			btnFalseName: 'Отменить',
		})
	}

	const setModalAcceptDispute = () => {
		setModalData({
			title: 'Взять спор на арбитраж',
			desc: `Взять спор на арбитраж, комиссия за арбитраж ${convertCentsToDollars(
				disputeData?.price
			)} USDT`,
			action: () => {
				acceptDispute(uuid as string, {
					// onSuccess: () => {
					//   disputePrice({ uuid: uuid, price: 1000 });
					// },
				})
			},
			isLoading: isAcceptDisputeLoading || isDisputePriceLoading,
			btnTrueName: 'Подтвердить',
			btnFalseName: 'Отменить',
		})
	}

	const setModalResolveDispute = () => {
		setModalData({
			title: 'Разделить обеспечение сделки2',
			desc: `Необходимо разделить обеспечение ${foolAmount} USDT`,
			action: () => {},
			isLoading: isDisputeResolveLoading,
			btnTrueName: 'Подтвердить',
			btnFalseName: 'Отменить',
		})
	}

	const setModalSendLink = () => {
		setModalData({
			title: 'Ссылка на чат',
			desc: `Прикрепите ссылку на чат`,
			action: () => {},
			isLoading: isSendMessageLoading,
			btnTrueName: 'Подтвердить',
			btnFalseName: 'Отменить',
			isSendLink: true,
		})
	}

	const halfCalculateAmount = (
		event: React.ChangeEvent<HTMLInputElement>
	): void => {
		let inputAmount: number = Number(event.target.value)
		setHalfErrorInput(null)

    const isValidFormat = /^\d*\.?\d*$/;

    if (!isValidFormat.test(event.target.value)) {
      setHalfErrorInput("Введите корректное число");
      return;
    }

		if (dealData) {
			if (inputAmount < 0) {
				inputAmount = 0
				setHalfErrorInput('Нельзя ввести меньше 0')
				return
			} else if (inputAmount > foolAmount) {
				inputAmount = foolAmount
				setHalfErrorInput('Нельзя ввести больше чем есть')
				return
			}

			const remainder: number = foolAmount - inputAmount

			setHalfSellerAmount(Number(inputAmount.toFixed(2)))
			setHalfBuyerAmount(Number(remainder.toFixed(2)))
		}
	}

	const getDisputeAction = (
		deal: Deal,
		dispute: DisputeData
	): JSX.Element | null => {
		const { state, deposit, created } = dispute
		const disputeDate = new Date(created)
		const currentDate = new Date()
		const disputePlus24Hours = new Date(
			disputeDate.getTime() + 24 * 60 * 60 * 1000
		)

		const firstButton = (
			<button
				type='button'
				disabled={isAcceptDisputeLoading || isDisputePriceLoading}
				className='mt-5 w-100 w-sm-75 w-md-50 d-block btn btn-info px-8 px-sm-10 mb-5'
				onClick={() => {
					setModalAcceptDispute()
				}}
			>
				{(isAcceptDisputeLoading || isDisputePriceLoading) && (
					<span
						className='spinner-border spinner-border-sm me-4'
						role='status'
						aria-hidden='true'
					></span>
				)}
				Взять спор на арбитраж
			</button>
		)

		if (state === 'new') {
			if (
				(deposit.seller || deposit.buyer) &&
				currentDate > disputePlus24Hours
			) {
				return (
					<>
						{firstButton}
						<button
							type='button'
							disabled={
								isAcceptDisputeLoading ||
								isDisputePriceLoading ||
								isDisputeResolveLoading
							}
							className='mt-5 w-100 w-sm-75 w-md-50 d-block btn btn-success px-8 px-sm-10 mb-5'
							onClick={() => {
								setModalHalfDispute()
							}}
						>
							{(isAcceptDisputeLoading ||
								isDisputePriceLoading ||
								isDisputeResolveLoading) && (
								<span
									className='spinner-border spinner-border-sm me-4'
									role='status'
									aria-hidden='true'
								></span>
							)}
							Решить спор в пользу оплатившей стороны
						</button>
					</>
				)
			} else if (
				(deposit.seller || deposit.buyer) &&
				currentDate <= disputePlus24Hours
			) {
				return (
					<>
						{firstButton}

						<div className='bg-light-success rounded border-success border border-dashed w-100 w-sm-75 w-md-75 d-block px-8 px-sm-10 mb-15  mx-auto mt-15 '>
							<div className='mx-5 d-flex flex-center  collapsible collapsed py-3 toggle mb-0'>
								<div className='text-gray-700 fw-bold fs-6 fs-sm-4  mb-0'>
									Ожидаем оплату арбитража второй стороной до{' '}
									{format(disputePlus24Hours, 'dd.MM.yyyy HH:mm')}
								</div>
							</div>
						</div>
					</>
				)
			}
		}

		return firstButton
	}

	const isDisputeActive = useMemo(() => {
		return disputeDataCurrent?.state === 'arbitration'
	}, [disputeDataCurrent?.state])

	return (
		<div className='card card-body'>
			{isDataDealLoading && !deal ? (
				<LoadingRingCard />
			) : deal?.seller?.uuid === currentUser?.uuid ||
			  deal?.buyer?.uuid === currentUser?.uuid ? (
				<>
					<div className='bg-light-danger rounded border-danger border border-dashed w-100 d-block px-md-10 my-15'>
						<div
							className='mx-5 d-flex flex-center collapsible collapsed py-3 toggle mb-0'
							data-bs-toggle='collapse'
						>
							<div className='btn btn-sm btn-icon mw-20px btn-active-color-primary me-2 me-sm-8'>
								<i className='ki-duotone ki-information fs-3x text-danger me-4'>
									<span className='path1'></span>
									<span className='path2'></span>
									<span className='path3'></span>
								</i>
							</div>

							<div className='text-gray-700 fw-bold fs-6 fs-sm-4  mb-0'>
								Свою сделку модерировать запрещено
							</div>
						</div>
					</div>
				</>
			) : deal ? (
				<div className='p-5 p-md-10'>
					<DealDataCardHeader deal={deal} />

					{disputeDataCurrent && (
						<>
							<div className='card-body  border-top border-danger pb-0  mb-9 mb-lg-10'></div>
							<div className='row  align-items-center mb-9 mb-lg-10'>
								<span className='col-lg-4 mb-2 mb-lg-0 fw-bolder fs-3'>
									Информация о споре
								</span>
							</div>
							<div className='mb-9 mb-lg-10 '>
								<div className='row  align-items-center mb-9 mb-lg-10'>
									<span className='col-lg-4 mb-2 mb-lg-0 fw-bold fs-4'>
										Статус
									</span>
									<span className='text-gray-600 fs-6 fw-semibold col-lg-8 fv-row'>
										<BadgeStatusDispute status={disputeDataCurrent.state} />
									</span>
								</div>
								<div className='row  align-items-center mb-9 mb-lg-10'>
									<span className='col-lg-4 mb-2 mb-lg-0 fw-bold fs-4'>
										Инициатор спора
									</span>
									<span className='text-gray-600 fs-6 fw-semibold col-lg-8 fv-row'>
										{translateRole(disputeDataCurrent?.initiator)}
									</span>
								</div>
								<div className='row  align-items-center mb-9 mb-lg-10'>
									<span className='col-lg-4 mb-2 mb-lg-0 fw-bold fs-4'>
										Описание спора
									</span>
									<span className='text-gray-600 fs-6 fw-semibold col-lg-8 fv-row '>
										{disputeDataCurrent?.description}
									</span>
								</div>
								<div className='row  align-items-center mb-9 mb-lg-10'>
									<span className='col-lg-4 mb-2 mb-lg-0 fw-bold fs-4'>
										Дата открытия
									</span>
									<span className='text-gray-600 fs-6 fw-semibold col-lg-8 fv-row'>
										{new Date(disputeDataCurrent?.created).toLocaleString(
											undefined,
											{
												year: 'numeric',
												month: 'numeric',
												day: 'numeric',
												hour: '2-digit',
												minute: '2-digit',
											}
										)}
									</span>
								</div>
								<div className='row  align-items-center'>
									<span className='col-lg-4 mb-2 mb-lg-0 fw-bold fs-4'>
										Комиссия {convertCentsToDollars(disputeDataCurrent?.price)}{' '}
										USDT
									</span>
									<span className='text-gray-600 fs-6 fw-semibold col-lg-8 fv-row'>
										{getCommissionPayer(disputeDataCurrent?.deposit)}
									</span>
								</div>
								{isDisputeActive && (
									<div className='row align-items-center mt-9 mt-lg-10'>
										<span className='col-lg-4 mb-2 mb-lg-0 fw-bold fs-4'>
											Обеспечение к разделу по решению арбитра
										</span>
										<span className='text-gray-600 fs-6 fw-semibold col-lg-8 fv-row '>
											{foolAmount} USDT
										</span>
									</div>
								)}
							</div>
							<div className='card-body  border-top border-danger pb-0 mb-9 mb-lg-10'></div>
							<div className='row  align-items-center mb-9 mb-lg-10'>
								<span className='col-lg-4 mb-2 mb-lg-0 fw-bolder fs-3'>
									Информация о сделке
								</span>
							</div>{' '}
						</>
					)}

					<DealDataCardBody deal={deal} description={description} />
					{history?.state === 'completed' ? (
						<BadgeReadyCompleteFool
							deal={deal}
							resolution={disputeDataCurrent?.resolution}
						/>
					) : (
						<>
							{/* Для арбитра  */}
							{deal?.seller?.uuid !== currentUser?.uuid &&
								history?.state === 'running' && (
									<div className='mt-15   d-flex justify-content-center align-items-center flex-column '>
										<BadgeReadyComplete deal={deal} />

										{disputeDataCurrent &&
											!isDisputeActive &&
											getDisputeAction(deal, disputeDataCurrent)}

										{currentUser?.uuid ===
											disputeDataCurrent?.arbitrator?.uuid &&
											isDisputeActive && (
												<>
													<button
														type='button'
														disabled={isSendMessageLoading}
														className='mt-5 w-100 w-sm-75 w-md-50 d-block btn btn-warning  px-8 px-sm-10 mb-5'
														onClick={() => {
															setModalSendLink()
														}}
													>
														{isSendMessageLoading && (
															<span
																className='spinner-border spinner-border-sm me-4'
																role='status'
																aria-hidden='true'
															></span>
														)}
														Отправить ссылку на чат
													</button>
													<button
														type='button'
														disabled={isDisputeResolveLoading}
														className='w-100 w-sm-75 w-md-50 d-block btn btn-success px-8 px-sm-10 mb-5'
														onClick={() => {
															setModalResolveDispute()
														}}
													>
														{isDisputeResolveLoading && (
															<span
																className='spinner-border spinner-border-sm me-4'
																role='status'
																aria-hidden='true'
															></span>
														)}
														Завершить спор
													</button>
												</>
											)}
									</div>
								)}
						</>
					)}
				</div>
			) : null}

			{error && (
				<div className='w-lg-50 mx-auto  mt-10'>
					<ShowError error={error} />
				</div>
			)}

			<ModalLayout
				title={modalData?.title}
				show={Boolean(modalData)}
				handleClose={() => setModalData(null)}
			>
				{modalData?.desc && (
					<div className='mb-10 fs-md-4 fs-7 fw-bold text-center'>
						{modalData?.desc}
					</div>
				)}

				{isDisputeActive && !modalData?.isSendLink && (
					<>
						<div className='mb-10 fs-md-4 fs-7 fw-bold text-center'>
							{`Укажите оплату продавцу: ${halfSellerAmount} USDT.
             Оплата продавцу ${halfSellerAmount} USDT, возврат покупателю ${halfBuyerAmount} USDT.`}
						</div>

						<div className='row mb-12'>
							<label className='col-lg-4 col-form-label required fw-bold fs-6'>
								Оплата продавцу
							</label>

							<div className='col-lg-8 fv-row'>
								<input
									placeholder='USDT'
									value={halfSellerAmount.toString()}
									onChange={halfCalculateAmount}
									step='1'
									type='number'
									name='halfSellerAmount'
									className={clsx(
										'form-control form-control-lg form-control-solid ',
										{
											'is-invalid': halfErrorInput,
										},
										{
											'is-valid': !halfErrorInput,
										}
									)}
									autoComplete='off'
								/>
								{halfErrorInput && (
									<div className='fv-plugins-message-container'>
										<div className='fv-help-block'>
											<span role='alert'>{halfErrorInput}</span>
										</div>
									</div>
								)}
							</div>
						</div>
					</>
				)}

				{modalData?.isSendLink && (
					<>
						<div className='row mb-12'>
							<label className='col-lg-4 col-form-label required fw-bold fs-6'>
								Ссылка:
							</label>

							<div className='col-lg-8 fv-row'>
								<input
									placeholder='http://...'
									value={sendLinkInput}
									onChange={e => {
										setSendLinkInput(e.target.value)
									}}
									type='text'
									name='sendLinkInput'
									className='form-control form-control-lg form-control-solid '
									autoComplete='off'
								/>
							</div>
						</div>
					</>
				)}

				<div className='p-2 d-flex justify-content-center align-items-center'>
					<button
						type='button'
						disabled={modalData?.isLoading}
						className='d-block me-5 me-sm-20 btn btn-success px-10 px-sm-15'
						onClick={() => {
							if (uuid) {
								isDisputeActive === false
									? modalData?.action()
									: modalData?.isSendLink
									? sendLinksUsers()
									: disputeResolve({
											uuid: uuid,
											sendData: {
												seller: convertDollarsToCents(halfSellerAmount),
												buyer: convertDollarsToCents(halfBuyerAmount),
											},
									  })
							}

							setModalData(null)
						}}
					>
						{modalData?.isLoading && (
							<span
								className='spinner-border spinner-border-sm me-4'
								role='status'
								aria-hidden='true'
							></span>
						)}
						{modalData?.btnTrueName}
					</button>

					<button
						type='button'
						className='d-block btn btn-danger px-8 px-sm-15'
						onClick={() => {
							setModalData(null)
						}}
					>
						{modalData?.btnFalseName}
					</button>
				</div>
			</ModalLayout>

			{isOpenShareModal && (
				<ShareDealModal
					isOpenModal={isOpenShareModal}
					setIsOpenModal={setIsOpenShareModal}
					uuid={uuid as string}
				/>
			)}

			<ToastTopLeft
				showToast={showSuccessToast}
				setShowToast={setShowSuccessToast}
				state='success'
			/>
			<ToastTopLeft
				showToast={showErrorToast}
				setShowToast={setShowErrorToast}
				state='danger'
			/>
		</div>
	)
}

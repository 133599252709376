/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { SidebarMenuItem } from "./SidebarMenuItem";
import { useAuth } from "../../../../../app/modules/auth";
import { SidebarMenuItemWithSub } from "./SidebarMenuItemWithSub";

const SidebarMenuMain = () => {
  const {
    isAdmin,
    isModerator,
    isContents,
    isArbitrator,
    isManager,
    isSupport,
  } = useAuth();

  return (
    <>
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            Меню
          </span>
        </div>
      </div>
      <SidebarMenuItem
        to="/apps/user-management/users"
        icon="profile-user"
        title="Пользователи"
        fontIcon="bi-layers"
      />
      {isModerator && (
        <SidebarMenuItem
          to="/apps/arbitrators-management/list"
          icon="security-user"
          title="Арбитры"
          fontIcon="bi-layers"
        />
      )}
      {isArbitrator && isAdmin && isModerator ? (
        <SidebarMenuItemWithSub
          to="/apps/dispute-management/*"
          icon="message-notif"
          title="Спор"
          fontIcon="bi-layers"
        >
          <SidebarMenuItem
            to="/apps/dispute-management/dispute"
            title="Арбитраж"
            hasBullet={true}
          />
          <SidebarMenuItem
            to="/apps/dispute-management/early-dispute"
            title="Досрочный арбитраж"
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>
      ) : isArbitrator ? (
        <SidebarMenuItem
          to="/apps/dispute-management/dispute"
          icon="message-notif"
          title="Спор"
          fontIcon="bi-layers"
        />
      ) : null}

      {isModerator && (
        <SidebarMenuItem
          to="/apps/deals-management/deals"
          icon="setting"
          title="Сделки"
          fontIcon="bi-layers"
        />
      )}
      {isModerator && (
        <SidebarMenuItem
          to="/apps/offer-management/offers"
          icon="chart"
          title="Предложения"
          fontIcon="bi-layers"
        />
      )}
      {isManager && (
        <SidebarMenuItem
          to="/apps/withdraw-management/list"
          icon="dollar"
          title="Заявки на вывод"
          fontIcon="bi-layers"
        />
      )}
      {isContents && (
        <SidebarMenuItem
          to="/apps/commission-management/list"
          icon="finance-calculator"
          title="Комиссии"
          fontIcon="bi-layers"
        />
      )}
      {isContents && (
        <SidebarMenuItem
          to="/apps/faq"
          icon="book-open"
          title="FAQ"
          fontIcon="bi-layers"
        />
      )}

      {(isAdmin || isArbitrator) && (
        <SidebarMenuItem
          to="/apps/metrics-management/metrics"
          icon="abstract-9"
          title="Метрики"
          fontIcon="bi-layers"
        />
      )}

      {isManager && (
        <SidebarMenuItem
          to="/apps/automatic-management/automatic"
          icon="abstract-8"
          title="Автовывод"
          fontIcon="bi-layers"
        />
      )}
    </>
  );
};

export { SidebarMenuMain };

import { IntlShape } from "react-intl";

export const VerificationName = "Verification";
export const AccountName = "Account";
export const InfoName = "Information | manuals";
export const DepositName = "Insurance deposit";
export const OthersName = "Other product | service";

export const getCategoryName = (
  intl: IntlShape,
  name: string | undefined
): string => {
  const VERIFICATION = intl.formatMessage({ id: "DEAL_CARD_VERIFICATION" });
  const ACCOUNT = intl.formatMessage({ id: "DEAL_CARD_ACCOUNT" });
  const INFO = intl.formatMessage({ id: "DEAL_CARD_INFO" });
  const DEPOSIT = intl.formatMessage({ id: "DEAL_CARD_DEPOSIT" });
  const OTHERS = intl.formatMessage({ id: "DEAL_CARD_OTHERS" });

  switch (name) {
    case VerificationName:
      return VERIFICATION;
    case AccountName:
      return ACCOUNT;
    case InfoName:
      return INFO;
    case DepositName:
      return DEPOSIT;
    case OthersName:
      return OTHERS;
    default:
      return "Category";
  }
};

/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";
import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Login } from "./components/Login";
import { ConfirmPassword } from "./components/ConfirmPassword";
import { ForgotPassword } from "./components/ForgotPassword";
import { HeaderWrapper } from "../../../_metronic/layout/components/header";
import { useAuth } from "./core/Auth";

const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add("bg-body");
    return () => {
      document.body.classList.remove("bg-body");
    };
  }, []);

  return (
    <div
      className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
      style={{
        background: "#fff",
      }}

      // style={{
      //   background: `url(${toAbsoluteUrl(
      //     "/media/bg/pinacle.png"
      //   )}) center center / cover no-repeat`,
      // }}
    >
      <HeaderWrapper />

      {/* begin::Content */}
      <div className="d-flex  flex-column flex-column-fluid p-lg-10 pb-lg-20 m-auto mt-sm-10 mt-md-15">
        {/* begin::Wrapper */}
        <div className="mw-400px mw-lg-800px bg-body rounded p-8 p-lg-20 mx-lg-20">
          <Outlet />
        </div>
        {/* end::Wrapper */}
      </div>
    </div>
  );
};

const AuthPage = () => {
  const { isAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isAuth && location.pathname.includes("/registration")) {
      navigate("/auth/login");
    }
  }, [isAuth, location]);

  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path="login" element={<Login />} />

        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset-password/*" element={<ConfirmPassword />} />
        <Route index element={<Login />} />
      </Route>
    </Routes>
  );
};

export { AuthPage };

import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from "react";

import { getUserByToken, useAuthLockPin } from "./_requests";
import { WithChildren } from "../../../../_metronic/helpers";
import { LayoutSplashScreen } from "../../../../_metronic/layout/core";
import { UserModel } from "./_models";
import {
  setGlobalLogoutHandler,
  setGlobalSaveAuthHandler,
  setLockUserHandler,
} from "./AuthHelpers";
import { useNavigate, useSearchParams } from "react-router-dom";

type AuthContextProps = {
  isAuth: boolean;
  setIsAuth: Dispatch<SetStateAction<boolean>>;
  saveAuth: () => void;
  logout: () => any;
  logoutIfCondition: () => any;
  currentUser: UserModel | undefined;
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>;
  isAddPinCode: boolean;
  setIsAddPinCode: Dispatch<SetStateAction<boolean>>;
  isLockUser: boolean;
  errorAuth: any;
  setErrorAuth: Dispatch<SetStateAction<any>>;
  userCode: string | null;
  setUserCode: Dispatch<SetStateAction<string | null>>;

  isAdmin: boolean;
  isModerator: boolean;
  isContents: boolean;
  isArbitrator: boolean;
  isManager: boolean;
  isSupport: boolean;
};

const initAuthContextPropsState = {
  isAuth: false,
  setIsAuth: () => {},
  saveAuth: () => {},
  logout: () => {},
  logoutIfCondition: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  isAddPinCode: false,
  setIsAddPinCode: () => {},
  isLockUser: false,
  errorAuth: null,
  setErrorAuth: () => {},
  userCode: null,
  setUserCode: () => {},

  isAdmin: false,
  isModerator: false,
  isContents: false,
  isArbitrator: false,
  isManager: false,
  isSupport: false,
};

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState);

const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const [isAuth, setIsAuth] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>(
    undefined
  );
  const [isAddPinCode, setIsAddPinCode] = useState<boolean>(false);
  const { mutate: setAuthLockPin } = useAuthLockPin();
  const [isLockUser, setIsLockUser] = useState(false);

  const [userCode, setUserCode] = useState<string | null>(null);

  const [errorAuth, setErrorAuth] = useState<any | null>(null);

  function saveAuth() {
    setIsAuth(true);
  }

  function resetAuth() {
    setIsAuth(true);
    setCurrentUser(undefined);
  }

  function logout() {
    if (currentUser) {
      setAuthLockPin();
      setCurrentUser(undefined);
    }
  }

  function logoutIfCondition() {
    setAuthLockPin();
    setCurrentUser(undefined);
  }

  function lockUser() {
    setIsLockUser(true);
    setIsAuth(false);
  }

  useEffect(() => {
    setGlobalLogoutHandler(logout);
    setGlobalSaveAuthHandler(resetAuth);
    setLockUserHandler(lockUser);
  }, []);

  const isAdmin = currentUser?.roles.includes("admin") || false;
  const isModerator = currentUser?.roles.includes("moderator") || false;
  const isContents = currentUser?.roles.includes("content") || false;
  const isArbitrator = currentUser?.roles.includes("arbitrator") || false;
  const isManager = currentUser?.roles.includes("manager") || false;
  const isSupport = currentUser?.roles.includes("support") || false;

  return (
    <AuthContext.Provider
      value={{
        isAuth,
        setIsAuth,
        saveAuth,
        logout,
        logoutIfCondition,
        currentUser,
        setCurrentUser,
        isAddPinCode,
        setIsAddPinCode,
        isLockUser,
        errorAuth,
        setErrorAuth,
        userCode,
        setUserCode,

        isAdmin,
        isModerator,
        isContents,
        isArbitrator,
        isManager,
        isSupport,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const AuthInit: FC<WithChildren> = ({ children }) => {
  const {
    currentUser,
    setCurrentUser,
    saveAuth,
    isAddPinCode,
    setIsAddPinCode,
    setErrorAuth,
    logoutIfCondition,
    userCode,
    setUserCode,
  } = useAuth();
  const [searchParams] = useSearchParams();

  const didRequest = useRef(false);
  const [showSplashScreen, setShowSplashScreen] = useState(true);

  const requestUser = async () => {
    try {
      setErrorAuth(null);

      if (!didRequest.current) {
        const data = await getUserByToken();

        const isAdminOrModeratorOrContents = data.roles.some(
          (role) =>
            role === "admin" ||
            role === "moderator" ||
            role === "content" ||
            role === "support" ||
            role === "arbitrator" ||
            role === "manager"
        );

        if (isAdminOrModeratorOrContents) {
          setCurrentUser(data);
          saveAuth();
        } else if (data && !isAdminOrModeratorOrContents) {
          setErrorAuth("Ошибка доступа");

          logoutIfCondition();
        }
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      setShowSplashScreen(false);
    }

    return () => (didRequest.current = true);
  };

  useEffect(() => {
    requestUser();
    if (currentUser) {
      setShowSplashScreen(false);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (searchParams) {
      const code = searchParams.get("code");

      if (code) {
        setUserCode(code);
      }

      if (code && !userCode) {
        logoutIfCondition();
        setTimeout(() => {
          requestUser();
        }, 0);
      }
    }
  }, [searchParams]);

  useEffect(() => {
    if (isAddPinCode) {
      requestUser();
      setIsAddPinCode(false);
    }
    // eslint-disable-next-line
  }, [isAddPinCode]);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>;
};

export { AuthProvider, useAuth, AuthInit };

import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { KTIcon } from "../../../_metronic/helpers";

interface Props {
  name?: string;
  style: string;
}

export const IconsCard: FC<Props> = ({ name, style }) => {
  const intl = useIntl();

  const [icon, setIcon] = useState<string | undefined>(undefined);

  const VERIFICATION = intl.formatMessage({ id: "DEAL_CARD_VERIFICATION" });
  const ACCOUNT = intl.formatMessage({ id: "DEAL_CARD_ACCOUNT" });
  const INFO = intl.formatMessage({ id: "DEAL_CARD_INFO" });
  const DEPOSIT = intl.formatMessage({ id: "DEAL_CARD_DEPOSIT" });
  const OTHERS = intl.formatMessage({ id: "DEAL_CARD_OTHERS" });

  useEffect(() => {
    if (name) {
      switch (name) {
        case VERIFICATION:
          setIcon("fingerprint-scanning");

          break;
        case ACCOUNT:
          setIcon("faceid");

          break;
        case INFO:
          setIcon("artificial-intelligence");

          break;
        case DEPOSIT:
          setIcon("dollar");

          break;
        case OTHERS:
          setIcon("rocket");
          break;
      }
    }
  }, [name]);

  return (
    <>
      <KTIcon iconName={`${icon}`} className={style} />
    </>
  );
};

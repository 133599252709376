import axios from "axios";
import {
  CreateDeal,
  Deal,
  DealConfirmDeal,
  DealHistory,
  DisputeAccept,
  GetFeeDeal,
  UpdDeal,
} from "./_models";
import { useMutation, useQueryClient } from "react-query";
import { DisputeData } from "../dispute-management/core/_models";

const CATEGORIES = `/api/categories/`;

const DEAL = `/api/deals/`;

const DISPUTE = `/api/disputes/`;

const USERS = `/api/users/`;

const SUPPORT = `/api/admin/support/`;

export const useGetDeals = () => {
  return useMutation<Deal[], any, any>("get-deals", async (reqData) => {
    const { data } = await axios.get(
      `${DEAL}?dispute=${reqData?.dispute}${
        reqData?.state ? `&state=${reqData?.state}` : ""
      }&limit=${reqData?.itemsValue}&offset=${reqData?.currentItemsValue}${
        reqData.dataName === "seller"
          ? `&seller.uuid=${reqData.data}`
          : reqData.dataName === "buyer"
          ? `&buyer.uuid=${reqData.data}`
          : ""
      }`
    );
    return data.deals;
  });
};

export const useCreateDeal = () => {
  return useMutation<Deal, any, CreateDeal>(
    "create-deal",
    async (deal: CreateDeal) => {
      const { data: response } = await axios.post(DEAL, deal);
      return response;
    }
  );
};

export const useGetDeal = () => {
  return useMutation<Deal, any, string>("get-deal", async (uuid) => {
    const { data: response } = await axios.get(`${DEAL}${uuid}/`);
    return response;
  });
};

export const useCompleteDeal = () => {
  return useMutation<Deal, any, string>(
    "complete-deal",
    async (uuid: string) => {
      const { data: response } = await axios.post(`${DEAL}${uuid}/complete/`);
      return response;
    }
  );
};

export const useGetDealHistory = () => {
  const queryClient = useQueryClient();

  return useMutation<DealHistory, any, string>(
    "get-history",
    async (uuid) => {
      const { data: response } = await axios.get(`${DEAL}${uuid}/history/`);
      return response.records[0];
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData("dealHistory", data);
      },
    }
  );
};

export const useUpdDeal = () => {
  return useMutation<Deal, any, UpdDeal>(
    "create-deal",
    async (deal: UpdDeal) => {
      const { data: response } = await axios.patch(
        `${DEAL}${deal.uuid}/`,
        deal.deal
      );
      return response;
    }
  );
};

export const useAcceptDeal = () => {
  return useMutation<Deal, any, string>("accept-deal", async (uuid) => {
    const { data: response } = await axios.post(`${DEAL}${uuid}/accept/`);
    return response;
  });
};

export const useConfirmDeal = () => {
  return useMutation<Deal, any, DealConfirmDeal>(
    "confirm-deal",
    async (data) => {
      const { data: response } = await axios.post(
        `${DEAL}${data.uuid}/confirm/`,
        data.money
      );
      return response;
    }
  );
};

export const useCancelDeal = () => {
  return useMutation<Deal, any, string>("cancel-deal", async (uuid: string) => {
    const { data: response } = await axios.post(`${DEAL}${uuid}/cancel/`);
    return response;
  });
};

export const useGetFeeDeal = () => {
  return useMutation("fee-deal", async (req: GetFeeDeal) => {
    const { data: response } = await axios.post(
      `${CATEGORIES}${req.categoryId}/fee/`,
      req.amount
    );
    return response;
  });
};

export const useGetOtherUserData = () => {
  return useMutation<any, any, any>("other-user", async (uuid) => {
    const { data } = await axios.get(`${USERS}${uuid}/`);
    return data;
  });
};

export const useGetDispute = () => {
  return useMutation<DisputeData, any, string>("get-dispute", async (uuid) => {
    const { data: response } = await axios.get(`${DISPUTE}${uuid}/`);
    return response;
  });
};

export const useAcceptDispute = () => {
  return useMutation<DisputeData, any, string>(
    "accept-dispute",
    async (uuid) => {
      const { data: response } = await axios.post(
        `${DISPUTE}${uuid}/arbitrate/`
      );
      return response;
    }
  );
};

export const useDisputePrice = () => {
  return useMutation<DisputeAccept, any, any>(
    "price-dispute",
    async ({ uuid, price }) => {
      const { data: response } = await axios.post(`${DISPUTE}${uuid}/price/`, {
        price: price,
      });
      return response;
    }
  );
};

export const useDisputeResolve = () => {
  return useMutation<DisputeAccept, any, any>(
    "resolve-dispute",
    async ({ uuid, sendData }) => {
      const { data: response } = await axios.post(
        `${DISPUTE}${uuid}/resolve/`,
        sendData
      );
      return response;
    }
  );
};

export const useExpirationDeal = () => {
  return useMutation<Deal, any, any>(
    "expiration-deal",
    async (data: {
      uuid: string;
      expiration: {
        expired_date: string;
      };
    }) => {
      const { data: response } = await axios.patch(
        `${DEAL}${data.uuid}/expiration/`,
        data.expiration
      );
      return response;
    }
  );
};

export const useGetFeeDispute = () => {
  return useMutation("fee-dispute", async (req: GetFeeDeal) => {
    const { data: response } = await axios.post(
      `${CATEGORIES}${req.categoryId}/fees/dispute/`,
      req.amount
    );
    return response.fee;
  });
};

export const useStartDispute = () => {
  return useMutation(
    "start-dispute",
    async ({ uuid, desc }: { uuid: string; desc: string }) => {
      const { data: response } = await axios.post(
        `${DEAL}${uuid}/dispute/moderator/`,
        {
          description: desc,
        }
      );
      return response;
    }
  );
};

export const useGetDisputeData = () => {
  return useMutation<DisputeData, any, string>("get-dispute", async (uuid) => {
    const { data: response } = await axios.get(`${DISPUTE}${uuid}/`);
    return response;
  });
};

export const useSendMessage = () => {
  return useMutation<any, any, any>(
    "send-message",
    async ({ uuid, sendData }) => {
      const { data: response } = await axios.post(
        `${SUPPORT}${uuid}/message/`,
        sendData
      );
      return response;
    }
  );
};

/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { LoginHeader } from "./LoginHeader";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ShowError } from "../../errors/components/ShowError";
import "./style.css";
import { UserLoginModal } from "./user-login-modal/UserLoginModal";
import { useAuthUnlockPin, useSetAuthCode } from "../core/_requests";
import { useAuth } from "../core/Auth";
import { UserLockModal } from "./user-lock-modal/UserLockModal";

interface CustomError extends Error {
  response?: {
    data: any;
    status: number;
    headers: any;
  };
}

const initialValuesMask = ["_", "_", "_", "_", "_", "_"];
const regex = /^[0-9]+$/;

export function Login() {
  const [inputValue, setInputValue] = useState("");
  const [inputMaskValues, setInputMaskValues] = useState(initialValuesMask);

  const [indexPass, setIndexPass] = useState<number>(0);
  const [updScore, setUpdScore] = useState<number>(0);
  const [previousValue, setPreviousValue] = useState("");
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  const [checkScore, setCheckScore] = useState<number>(0);
  const [inputMaskBg, setInputMaskBg] = useState<boolean | null>(null);
  const [error, setError] = useState<any | null>(null);

  const [isPinHave, setIsPinHave] = useState<boolean | undefined>(undefined);
  const [errorUserCode, setErrorUserCode] = useState<any | null>(null);

  const [searchParams] = useSearchParams();

  const {
    isAuth,
    setIsAddPinCode,
    isLockUser,
    errorAuth,
    setIsAuth,
    userCode,
    setUserCode,
  } = useAuth();

  const {
    mutate: setAuthCode,
    isLoading: isSetAuthCodeLoading,
    data: authCodeData,
    error: setAuthCodeError,
  } = useSetAuthCode();

  const {
    mutate: setAuthUnlockPin,
    data: authUnlockPinData,
    error: authUnlockPinError,
  } = useAuthUnlockPin();

  useEffect(() => {
    if (authUnlockPinData) {
      setInputMaskBg(true);
      setIsAddPinCode(true);
    }
  }, [authUnlockPinData]);

  useEffect(() => {
    if (searchParams) {
      const code = searchParams.get("code");

      setUserCode(code);
    }
  }, [searchParams]);

  useEffect(() => {
    if (userCode) {
      setAuthCode(userCode);
    }
  }, [userCode]);

  useEffect(() => {
    if (authCodeData) {
      if (authCodeData) {
        authCodeData?.profile
          ? setIsPinHave(authCodeData?.profile)
          : setIsAuth(false);
      }
    }
  }, [authCodeData]);

  useEffect(() => {
    if (setAuthCodeError instanceof Error) {
      const customError = setAuthCodeError as CustomError;

      setErrorUserCode({ error: customError });
    }
  }, [setAuthCodeError]);

  useEffect(() => {
    if (errorAuth) {
      setError({ error: errorAuth });
      handlerInvalidPassword();
    }
  }, [errorAuth]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    if (!regex.test(value) && value !== "") return;

    if (value.length > 6) {
      return;
    } else {
      if (error || inputMaskBg !== null) {
        setError(null);
        setInputMaskBg(null);
      }

      const splitValue: string[] = value.split("");
      const newValues: string[] = [];

      setInputValue(value);

      for (let i = 0; i < 6; i++) {
        if (
          splitValue[i] &&
          i === splitValue.length - 1 &&
          value.length > previousValue.length
        ) {
          newValues.push(splitValue[i]);
        } else if (splitValue[i]) {
          newValues.push("*");
        } else {
          newValues.push("_");
        }
      }

      setInputMaskValues(newValues);

      if (timer) {
        clearTimeout(timer);
      }

      const newTimer = setTimeout(() => {
        /// Проверяем, добавлен ли символ или удален
        if (value.length > previousValue.length) {
          setUpdScore(updScore + 1);
        }
      }, 1000);

      setIndexPass(splitValue.length - 1);
      setPreviousValue(value);
      setTimer(newTimer);
    }
  };

  useEffect(() => {
    if (indexPass > 0) {
      updInputMask();

      if (indexPass === 5) {
        setAuthUnlockPin(inputValue);
      }
    }
  }, [updScore]);

  const updInputMask = () => {
    const newValues = inputMaskValues.map((el, idx) => {
      if (idx === indexPass) {
        return (el = "*");
      }
      return el;
    });

    setInputMaskValues(newValues);
  };

  useEffect(() => {
    if (authUnlockPinError) {
      setCheckScore(checkScore + 1);
    }
  }, [authUnlockPinError]);

  const handlerInvalidPassword = () => {
    setInputMaskBg(false);
    setInputValue("");
    setInputMaskValues(initialValuesMask);
  };

  useEffect(() => {
    if (checkScore > 0) {
      switch (checkScore) {
        case 1:
          setError({ error: "Неверный код-пароль." });
          handlerInvalidPassword();
          break;
        case 2:
          setError({ error: "Неверный пароль. Осталось 3 попытки." });
          handlerInvalidPassword();

          break;
        case 3:
          setError({ error: "Неверный пароль. Осталось 2 попытки." });
          handlerInvalidPassword();

          break;
        case 4:
          setError({ error: "Неверный пароль. Осталось 1 попытка." });
          handlerInvalidPassword();

          break;
        case 5:
          setError({
            error:
              "Вы несколько раз ввели неправильный код-пароль. Доступ в сервису заблокирован.",
          });
          handlerInvalidPassword();

          break;
      }
    }
  }, [checkScore]);

  return (
    <div>
      <LoginHeader />
      <div
        className={`${
          inputMaskBg === false ? "shake" : ""
        } d-flex flex-wrap flex-stack justify-content-center algin-items-center mb-15`}
        style={{
          position: "relative",
        }}
      >
        {inputMaskValues.map((value, index) => (
          <div
            key={index}
            className={`btn display-3 btn-outline btn-outline-${
              inputMaskBg === null ? null : inputMaskBg ? "success" : "danger"
            } px-5 pt-4 pb-2 w-45px`}
            style={{ cursor: "pointer", marginRight: "6px" }}
          >
            <span className="">{value}</span>
          </div>
        ))}

        <input
          type="phone"
          value={inputValue}
          style={{
            opacity: "0",
            position: "absolute",
            width: "100%",
            height: "100%",
            top: "0",
            left: "0",
          }}
          className="form-control form-control-lg form-control-solid"
          placeholder="XXX-XXX"
          autoComplete="off"
          onChange={(e) => {
            handleInputChange(e);
          }}
        />
      </div>

      <div className="mb-10 mw-500px mx-auto text-center">
        {error && ShowError(error)}
      </div>

      {/* <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
        <div />
        {checkScore < 6 ? (
          <Link to="/auth/forgot-password" className="link-primary">
            {intl.formatMessage({ id: "AUTH.GENERAL.FORGOT_BUTTON" })} ?
          </Link>
        ) : (
          <Link to="/auth/forgot-password" className="link-primary">
            Восстановить доступ.
          </Link>
        )}{" "}
      </div> */}

      {isPinHave !== true && !isAuth && !isLockUser && (
        <UserLoginModal
          userCode={userCode}
          error={errorUserCode}
          isloading={isSetAuthCodeLoading}
        />
      )}

      {isLockUser && <UserLockModal />}
    </div>
  );
}

import axios from "axios";
import { UserModel } from "..";
import { useMutation } from "react-query";

export const GET_USER_BY_ACCESSTOKEN_URL = `/api/profile/`;

export const SET_AUTH_CODE = `/api/auth/code/`;

export const SET_AUTH_PIN = `/api/profile/register/`;

export const AUTH_UNLOCK_PIN = `/api/auth/unlock/`;

export const AUTH_LOCK_PIN = `/api/auth/lock/`;

export async function getUserByToken(): Promise<UserModel> {
  const response = await axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL);
  return response.data;
}

export const useSetAuthCode = () => {
  return useMutation("set-auth-code", async (code: string) => {
    const { data: response } = await axios.post(SET_AUTH_CODE, {
      code,
    });
    return response;
  });
};

export const useSetAuthPin = () => {
  return useMutation("set-auth-pin", async (pin: string) => {
    const { data: response } = await axios.post(SET_AUTH_PIN, {
      pin,
    });
    return response;
  });
};

export const useAuthUnlockPin = () => {
  return useMutation("unlock-pin", async (pin: string) => {
    const response = await axios.post(AUTH_UNLOCK_PIN, {
      pin,
    });
    return response.status;
  });
};

export const useAuthLockPin = () => {
  return useMutation("lock-pin", async () => {
    const { data: response } = await axios.post(AUTH_LOCK_PIN, {});
    return response;
  });
};

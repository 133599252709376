let globalSaveAuthHandler = () => {};
let globalLogoutHandler = () => {};
let lockUserHandler = () => {};

export const setGlobalSaveAuthHandler = (handler: () => void) => {
  globalSaveAuthHandler = handler;
};

export const setGlobalLogoutHandler = (handler: () => void) => {
  globalLogoutHandler = handler;
};

export const setLockUserHandler = (handler: () => void) => {
  lockUserHandler = handler;
};

const handleLogin = () => {
  globalLogoutHandler();
};

const handleEnterPin = () => {
  globalSaveAuthHandler();
};

const handleLockUser = () => {
  lockUserHandler();
};

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = "application/json";
  axios.interceptors.request.use(
    (config: { headers: { Authorization: string } }) => {
      return config;
    },
    (err: any) => Promise.reject(err)
  );
  axios.interceptors.response.use(
    (response: any) => response,
    (error: any) => {
      // Перехватываем только ошибки с определенными статусами
      if (error.response && [400, 401, 403].includes(error.response.status)) {
        if (error.response.status === 401) {
          // Ошибка аутентификации, необходимо залогиниться
          handleLogin();
        } else if (
          error.response.status === 400 &&
          error.response.data.code === "session-lock"
        ) {
          // Необходимо ввести ПИН-код
          handleEnterPin();
        } else if (
          error.response.status === 403 &&
          error.response.data.code === "forbidden"
        ) {
          handleLockUser();
        }
      }

      return Promise.reject(error);
    }
  );
}

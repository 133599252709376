import type { FC } from "react";
import React from "react";
import { Modal } from "react-bootstrap";

import { createPortal } from "react-dom";
import { KTIcon } from "../../helpers";

type Props = {
  isLoading?: boolean;
  title: React.ReactNode;
  children: React.ReactNode;
  onOkTitle?: React.ReactNode;
  show: boolean;
  onOk?: () => void;
  handleClose: () => void;
};

const modalsRoot = document.getElementById("root-modals") || document.body;

export const ModalLayout: FC<Props> = React.memo(
  ({ show, handleClose, children, onOk, title, onOkTitle, isLoading }) => {
    return createPortal(
      <Modal
        id="kt_modal_create_app"
        tabIndex={-1}
        aria-hidden="true"
        dialogClassName="modal-dialog modal-dialog-centered mw-600px"
        show={show}
        onHide={handleClose}
        // onEntered={loadStepper}
        backdrop={true}
      >
        <div className="modal-header">
          <h2 className="mb-0">{title}</h2>
          {/* begin::Close */}
          <div
            className="btn btn-icon btn-sm btn-primary btn-active-icon-light"
            onClick={handleClose}
          >
            <KTIcon className="fs-1" iconName="cross" />
          </div>
          {/* end::Close */}
        </div>

        <div className="modal-body py-lg-10 px-lg-10">{children}</div>
        {onOk && (
          <button
            disabled={isLoading}
            type="button"
            className="align-self-end m-10 btn btn-lg btn-primary"
            data-kt-stepper-action="next"
            onClick={onOk}
          >
            {onOkTitle || "Ok"}{" "}
            <KTIcon iconName="arrow-right" className="fs-3 ms-1 me-0" />
          </button>
        )}
      </Modal>,
      modalsRoot
    );
  }
);

import { FC, useEffect } from "react";
import { CreateDisputeModalHeader } from "./CreateDisputeModalHeader";
import { CreateDisputeModalBody } from "./CreateDisputeModalBody";
import ModalWrapper from "../../../components/ModalWrapper";
import { Deal } from "../../../core/_models";

interface Props {
  isOpenModal: boolean;
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  deal: Deal;
}

export const CreateDisputeModal: FC<Props> = ({
  isOpenModal,
  setIsOpenModal,
  deal,
}) => {
  useEffect(() => {
    document.body.classList.add("modal-open");
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);

  return (
    <>
      <ModalWrapper isOpenModal={isOpenModal}>
        {/* begin::Modal dialog */}
        <div className="modal-dialog modal-dialog-centered mw-600px">
          {/* begin::Modal content */}
          <div className="modal-content">
            <CreateDisputeModalHeader setIsOpenModal={setIsOpenModal} />
            {/* begin::Modal body */}
            <div className="modal-body  mx-5 mx-xl-10 my-2">
              <CreateDisputeModalBody
                deal={deal}
                setIsOpenModal={setIsOpenModal}
              />
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </ModalWrapper>
      {/* begin::Modal Backdrop */}
      <div className="modal-backdrop fade show"></div>
      {/* end::Modal Backdrop */}
    </>
  );
};

import { FC } from "react";
import { TelegramLoginButton } from "../TelegramLoginButton";
import { ShowError } from "../../../errors/components/ShowError";
import "../style.css";

interface UserCode {
  userCode: string | null;
  error: any;
  isloading: boolean;
}

export const UserLockModalBody: FC = () => {
  return (
    <>
      <>
        <div className="fs-6 mb-10">
          <p>
            Обратитесь в тех. поддержку в боте
            <b> @Gross_escrow</b>
          </p>
        </div>

        <div className="d-flex flex-center">
          <TelegramLoginButton />
        </div>
      </>
    </>
  );
};

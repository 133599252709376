import { FC } from "react";
import { TelegramLoginButton } from "../TelegramLoginButton";
import { ShowError } from "../../../errors/components/ShowError";
import "../style.css";

interface UserCode {
  userCode: string | null;
  error: any;
  isloading: boolean;
}

export const UserLoginModalBody: FC<UserCode> = ({
  userCode,
  error,
  isloading,
}) => {
  return (
    <>
      {userCode === null ? (
        <>
          <div className="fs-6 mb-10">
            <p>
              После нажатия кнопки вы перейдете в диалог с нашим ботом{" "}
              <b>@Gross_escrow</b>
            </p>

            <p>
              В диалоге с ботом нажмите <b>/start</b>
            </p>
          </div>

          <div className="d-flex flex-center">
            <TelegramLoginButton />
          </div>
        </>
      ) : error ? (
        <>
          <div className="fs-6 mb-10">
            <div className="mb-13 mw-400px mx-auto text-center">
              {error && ShowError(error)}
            </div>
          </div>

          <div className="d-flex flex-center">
            <TelegramLoginButton />
          </div>
        </>
      ) : isloading ? (
        <>
          <div className="splashScreen">
            <div className="lightLogo">
              <div className="ldsDualRing"></div>
            </div>

            {/* <span style={{ color: "rgb(0, 0, 0)" }}>Обновляем данные...</span> */}
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

import { FC, lazy, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";
import { WithChildren } from "../../_metronic/helpers";
import { DealCard } from "../modules/apps/deals-card/DealCard";
import { ArbiterCard } from "../modules/apps/deals-card/ArbiterCard";
import { ArbiterCardEarly } from "../modules/apps/deals-card/ArbiterCardEarly";

const PrivateRoutes = () => {
  const UsersPage = lazy(
    () => import("../modules/apps/user-management/UsersPage")
  );

  const ProfilePage = lazy(() => import("../modules/apps/profile/ProfilePage"));

  const FaqPage = lazy(() => import("../modules/apps/faq/FaqPage"));

  const DealsPage = lazy(
    () => import("../modules/apps/deals-management/DealsPage")
  );

  const OffersPage = lazy(
    () => import("../modules/apps/offers-management/OffersPage")
  );

  const DisputePage = lazy(
    () => import("../modules/apps/dispute-management/DisputePage")
  );

  const WithdrawPage = lazy(
    () => import("../modules/apps/withdraw-management/WithdrawPage")
  );

  const CommissionPage = lazy(
    () => import("../modules/apps/commission-management/CommissionPage")
  );

  const ArbitratorsPage = lazy(
    () => import("../modules/apps/arbitrators-management/ArbitratorsPage")
  );

  const MetricsPage = lazy(
    () => import("../modules/apps/metrics-management/MetricsPage")
  );

  const AutomaticPage = lazy(
    () => import("../modules/apps/automatic-wthdrawal-management/MetricsPage")
  );

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route
          path="auth/*"
          element={<Navigate to="/apps/user-management" />}
        />
        {/* Pages */}
        <Route path="/" element={<Navigate to="/apps/user-management" />} />
        <Route
          path="apps/user-management/*"
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />

        <Route
          path="apps/profile/*"
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />

        <Route
          path="apps/*"
          element={
            <SuspensedView>
              <FaqPage />
            </SuspensedView>
          }
        />

        <Route
          path="apps/offer-management/*"
          element={
            <SuspensedView>
              <OffersPage />
            </SuspensedView>
          }
        />

        <Route
          path="apps/deals-management/*"
          element={
            <SuspensedView>
              <DealsPage />
            </SuspensedView>
          }
        />

        <Route
          path="apps/dispute-management/*"
          element={
            <SuspensedView>
              <DisputePage />
            </SuspensedView>
          }
        />

        <Route
          path="apps/deal/:uuid"
          element={
            <SuspensedView>
              <DealCard />
            </SuspensedView>
          }
        />

        <Route
          path="apps/commission-management/*"
          element={
            <SuspensedView>
              <CommissionPage />
            </SuspensedView>
          }
        />

        <Route
          path="apps/dispute-deal/:uuid"
          element={
            <SuspensedView>
              <ArbiterCard />
            </SuspensedView>
          }
        />

        <Route
          path="apps/early-deal/:uuid"
          element={
            <SuspensedView>
              <ArbiterCardEarly />
            </SuspensedView>
          }
        />

        <Route
          path="apps/withdraw-management/*"
          element={
            <SuspensedView>
              <WithdrawPage />
            </SuspensedView>
          }
        />

        <Route
          path="apps/arbitrators-management/*"
          element={
            <SuspensedView>
              <ArbitratorsPage />
            </SuspensedView>
          }
        />

        <Route
          path="apps/metrics-management/*"
          element={
            <SuspensedView>
              <MetricsPage />
            </SuspensedView>
          }
        />

        <Route
          path="apps/automatic-management/*"
          element={
            <SuspensedView>
              <AutomaticPage />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  TopBarProgress.config({
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };

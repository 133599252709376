import { FC } from "react";
import { ConfirmItem, Deal } from "../../core/_models";
import { convertCentsToDollars } from "../../../../utils";
import { useIntl } from "react-intl";
import { ResolutionScheme } from "../../dispute-management/core/_models";

interface Props {
  deal: Deal;
  resolution?: ResolutionScheme;
  isExpiredComplete?: boolean;
}

export const BadgeReadyCompleteFool: FC<Props> = ({
  deal,
  resolution,
  isExpiredComplete,
}) => {
  const intl = useIntl();

  const isCompletion = (confirm: ConfirmItem[]): string => {
    if (deal.dispute && resolution) {
      if (resolution?.buyer === 0 || resolution?.seller === 0) {
        return intl.formatMessage(
          { id: "DEAL.COMPLETED_AFTER_DISPUTE_SELLER_ONLY" },
          {
            amount: convertCentsToDollars(resolution?.seller),
          }
        );
      } else {
        return intl.formatMessage(
          { id: "DEAL.COMPLETED_AFTER_DISPUTE" },
          {
            sellerAmount: convertCentsToDollars(resolution?.seller),
            buyerAmount: convertCentsToDollars(resolution?.buyer),
          }
        );
      }
    } else if (confirm.length === 2) {
      if (confirm[0]?.seller === 0 && confirm[1]?.seller === 0) {
        return intl.formatMessage(
          { id: "DEAL.COMPLETED" },
          {
            amount: convertCentsToDollars(confirm[0]?.seller),
          }
        );
      } else if (confirm[0]?.buyer === 0 && confirm[1]?.buyer === 0) {
        return intl.formatMessage(
          { id: "DEAL.COMPLETED" },
          {
            amount: convertCentsToDollars(confirm[0]?.seller),
          }
        );
      } else {
        return intl.formatMessage(
          { id: "DEAL.COMPLETED_WITH_REFUND" },
          {
            sellerAmount: convertCentsToDollars(confirm[0]?.seller),
            buyerAmount: convertCentsToDollars(confirm[0]?.buyer),
          }
        );
      }
    } else if (confirm.length === 3) {
      const index = confirm.findIndex((item) => item.user.moderator === true);
      return intl.formatMessage(
        { id: "DEAL.COMPLETED_WITH_REFUND" },
        {
          sellerAmount: convertCentsToDollars(confirm[index]?.seller),
          buyerAmount: convertCentsToDollars(confirm[index]?.buyer),
        }
      );
    } else {
      return "Сделка завершена";
    }
  };

  return (
    <>
      <div className="bg-light-success rounded border-success border border-dashed w-100 w-sm-75 w-md-75 d-block px-8 px-sm-10 mb-15  mx-auto mt-15 ">
        <div className="mx-5 d-flex flex-center  collapsible collapsed py-3 toggle mb-0">
          <div className="btn btn-sm btn-icon mw-20px btn-active-color-primary me-2 me-sm-8">
            <i className="ki-duotone ki-information fs-3x text-success me-4">
              <span className="path1"></span>
              <span className="path2"></span>
              <span className="path3"></span>
            </i>
          </div>
          <div className="text-gray-700 fw-bold fs-6 fs-sm-4  mb-0 ">
            {isCompletion(deal?.confirm)}
          </div>
        </div>
      </div>
    </>
  );
};

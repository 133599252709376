import { FC, useEffect } from "react";
import { ShareDealModalHeader } from "./ShareDealModalHeader";
import { ShareDealModalBody } from "./ShareDealModalBody";

interface Props {
  isOpenModal?: boolean;
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  uuid: string;
}

export const ShareDealModal: FC<Props> = ({
  isOpenModal,
  setIsOpenModal,
  uuid,
}) => {
  useEffect(() => {
    document.body.classList.add("modal-open");
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);

  return (
    <>
      <div
        className="modal fade show d-block"
        id="kt_modal_add_user"
        role="dialog"
        tabIndex={-1}
        aria-modal="true"
      >
        {/* begin::Modal dialog */}
        <div className="modal-dialog modal-dialog-centered mw-600px">
          {/* begin::Modal content */}
          <div className="modal-content">
            <ShareDealModalHeader setIsOpenModal={setIsOpenModal} />
            {/* begin::Modal body */}
            <div className="modal-body  mx-5 mx-xl-10 my-2">
              <ShareDealModalBody uuid={uuid} />
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className="modal-backdrop fade show"></div>
      {/* end::Modal Backdrop */}
    </>
  );
};

import styles from "./loadingStyle.module.css";

export function LoadingRingCard() {
  return (
    <div className={styles.splashScreen}>
      <div className={styles.lightLogo}>
        <div className={styles.ldsDualRing}></div>
      </div>

      <span style={{ color: "rgb(0, 0, 0)" }}>Загружаем данные...</span>
    </div>
  );
}

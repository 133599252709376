import { FC, useRef, useState } from "react";
import QRCode from "react-qr-code";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { KTIcon } from "../../../../../../_metronic/helpers";

interface Props {
  uuid: string;
}

export const ShareDealModalBody: FC<Props> = ({ uuid }) => {
  const [copied, setCopied] = useState(false);

  return (
    <>
      <div className="py-5">
        <div
          className="bg-white  m-auto border border-gray-300 border-dashed rounded mb-sm-0"
          style={{ width: "100%", maxWidth: "330px" }}
        >
          {`${window?.location?.origin}/pages/create/verification/card/${uuid}` && (
            <QRCode
              size={120}
              style={{
                display: "block",
                height: "auto",
                width: "100%",
                margin: "auto",
              }}
              value={`${window?.location?.origin}/pages/create/verification/card/${uuid}`}
              viewBox={`0 0 256 256`}
            />
          )}
        </div>

        {/* <a
          href={`/pages/create/verification/card/${uuid}`}
          className="link fs-lg-2 fs-6 fw-bold me-1 "
          rel="noreferrer"
        >
          
        </a> */}

        <OverlayTrigger
          key="copy-to-clipboard"
          placement="top"
          overlay={
            <Tooltip id="tooltip-copy-to-clipboard">
              {copied ? "Скопировано" : "Копировать"}
            </Tooltip>
          }
        >
          <CopyToClipboard
            text={`${window?.location?.origin}/pages/create/verification/card/${uuid}`}
            onCopy={() => {
              setCopied(true);
              setTimeout(() => {
                setCopied(false);
              }, 500);
            }}
          >
            <div className="mx-auto w-100 mt-8">
              <button
                type="button"
                className="w-100 btn btn-outline-secondary btn-sm"
                // onClick={() => showFilters(!isFilters)}
              >
                <span className="fs-2 text-gray-800 fw-bold me-5">
                  Копировать ссылку
                </span>
                <KTIcon iconName="copy" className="fs-2 text-muted me-1 mb-1" />
              </button>
            </div>
          </CopyToClipboard>
        </OverlayTrigger>
      </div>
    </>
  );
};

import type { FC } from "react";
import React from "react";

interface Props {}

export const LoginHeader: FC<Props> = React.memo(() => {
  return (
    <>
      <div className="mb-13 text-center">
        {/* begin::Title */}
        <h1 className="text-dark fw-semibold mb-3 display-6">
          Введите код-пароль для входа
        </h1>
        {/* end::Title */}
      </div>

      {/* <SocialBtns /> */}

      {/* <div className="separator separator-content my-14">
        <span className="w-125px text-gray-500 fw-semibold fs-7">
          {intl.formatMessage({ id: "GLOBAL.OR" })}
        </span>
      </div> */}
    </>
  );
});

import { FC } from "react";
import { ConfirmItem, Deal } from "../../core/_models";
import { convertCentsToDollars } from "../../../../utils";

interface Props {
  deal: Deal;
}

export const BadgeReadyComplete: FC<Props> = ({ deal }) => {
  const isOwnsUuid = (confirm: ConfirmItem[], uuid: string): boolean => {
    return confirm.some((item) => item?.user?.uuid === uuid);
  };

  const getMessageBuyer = (
    confirm: ConfirmItem[],
    buyerUuid: string
  ): string => {
    const confirmation = confirm.find((item) => item?.user?.uuid === buyerUuid);

    if (confirmation) {
      return confirmation?.buyer === 0
        ? "Покупатель готов завершить сделку с полной оплатой"
        : confirmation?.seller === 0
        ? "Покупатель готов отменить сделку."
        : `Покупатель готов завершить сделку с частичной оплатой.
        Оплата продавцу ${convertCentsToDollars(
          confirmation?.seller
        )} USDT, возврат покупателю ${convertCentsToDollars(
            confirmation?.buyer
          )} USDT`;
    }
    return "";
  };

  const getMessageSeller = (confirm: any[], sellerUuid: string): string => {
    const confirmation = confirm.find(
      (item) => item?.user?.uuid === sellerUuid
    );

    if (confirmation) {
      return confirmation?.buyer === 0
        ? "Продавец готов завершить сделку с полной оплатой"
        : confirmation?.seller === 0
        ? "Продавец готов отменить сделку."
        : `Продавец готов завершить сделку с частичной оплатой.
        Оплата продавцу ${convertCentsToDollars(
          confirmation?.seller
        )} USDT, возврат покупателю ${convertCentsToDollars(
            confirmation?.buyer
          )} USDT`;
    }
    return "";
  };

  const getMessageModerator = (
    confirm: ConfirmItem[],
    sellerUuid: string,
    buyerUuid: string
  ): string | null => {
    if (confirm.length) {
      const nonMatchingItem = confirm.find(
        (item) =>
          item?.user?.uuid !== sellerUuid && item?.user?.uuid !== buyerUuid
      );

      if (nonMatchingItem) {
        return confirm[0]?.buyer === 0
          ? "Модератор готов завершить сделку с полной оплатой"
          : confirm[0]?.seller === 0
          ? "Модератор готов отменить сделку."
          : `Модератор готов завершить сделку с частичной оплатой. Оплата продавцу $${convertCentsToDollars(
              confirm[0]?.seller
            )} USDT, возврат покупателю $${convertCentsToDollars(
              confirm[0]?.buyer
            )} USDT`;
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  return (
    <>
      {deal?.confirm.length > 0 &&
        isOwnsUuid(deal?.confirm, deal?.buyer?.uuid) && (
          <div className="bg-light-info rounded border-info border border-dashed w-100 w-sm-75 w-md-75 d-block px-md-10 mb-10">
            <div
              className="mx-5 d-flex flex-center collapsible collapsed py-3 toggle mb-0"
              data-bs-toggle="collapse"
            >
              <div className="btn btn-sm btn-icon mw-20px btn-active-color-primary me-2 me-sm-8">
                <i className="ki-duotone ki-information me-4 fs-3x text-info">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                </i>
              </div>
              <div className="text-gray-700 fw-bold fs-6 fs-sm-4  mb-0">
                {getMessageBuyer(deal?.confirm, deal?.buyer?.uuid)}
              </div>
            </div>
          </div>
        )}
      {deal?.confirm.length > 0 &&
        isOwnsUuid(deal?.confirm, deal?.seller?.uuid) && (
          <div className="bg-light-primary rounded border-primary border border-dashed w-100 w-sm-75 w-md-50 d-block px-md-10 mb-10">
            <div className="mx-5 d-flex flex-center collapsible collapsed py-3 toggle mb-0">
              <div className="btn btn-sm btn-icon mw-20px btn-active-color-primary me-2 me-sm-8">
                <i className="ki-duotone ki-information me-4 fs-3x text-primary">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                </i>
              </div>
              <div className="text-gray-700 fw-bold fs-6 fs-sm-4  mb-0 ">
                {getMessageSeller(deal?.confirm, deal?.seller?.uuid)}
              </div>
            </div>
          </div>
        )}

      {deal?.confirm.length > 2 &&
        getMessageModerator(
          deal?.confirm,
          deal?.seller?.uuid,
          deal?.buyer?.uuid
        ) && (
          <div className="bg-light-warning rounded border-warning border border-dashed w-100 w-sm-75 w-md-50 d-block px-md-10 mb-10">
            <div className="mx-5 d-flex flex-center collapsible collapsed py-3 toggle mb-0">
              <div className="btn btn-sm btn-icon mw-20px btn-active-color-warning me-6 me-sm-8">
                <i className="ki-duotone ki-information me-4 fs-3x text-warning">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                </i>
              </div>
              <div className="text-gray-700 fw-bold fs-6 fs-sm-4  mb-0">
                {getMessageModerator(
                  deal?.confirm,
                  deal?.seller?.uuid,
                  deal?.buyer?.uuid
                )}
              </div>
            </div>
          </div>
        )}
    </>
  );
};

import { FC, useMemo } from "react";
import { Deal, DealHistory } from "../../core/_models";
import { IconsCard } from "../../../components/IconsCard";
import { useAuth } from "../../../auth";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { BadgeStatusDeal } from "../../../components/BadgeStatusDeal";
import { BadgeTimeStatusDeal } from "./BadgeTimeStatusDeal";
import { useQueryClient } from "react-query";
import { getCategoryName } from "../../../components/helpers";

interface Props {
  deal: Deal;
}

export const DealDataCardHeader: FC<Props> = ({ deal }) => {
  const intl = useIntl();
  const VERIFICATION = intl.formatMessage({ id: "DEAL_CARD_VERIFICATION" });
  const ACCOUNT = intl.formatMessage({ id: "DEAL_CARD_ACCOUNT" });
  const INFO = intl.formatMessage({ id: "DEAL_CARD_INFO" });
  const DEPOSIT = intl.formatMessage({ id: "DEAL_CARD_DEPOSIT" });
  const OTHERS = intl.formatMessage({ id: "DEAL_CARD_OTHERS" });

  const { currentUser } = useAuth();
  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const history = queryClient.getQueryData("dealHistory") as DealHistory;
  const isHistoryLoading = queryClient.isFetching("dealHistory") > 0;

  function formatIsoToDate(isoString: any) {
    const date = new Date(isoString);

    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${day}.${month}.${year} ${hours}:${minutes}`;
  }

  const getHeading = (name: string | undefined) => {
    switch (name) {
      case VERIFICATION:
        return "продаже верификации";
      case ACCOUNT:
        return "продаже аккаунта";
      case INFO:
        return "продаже информации | мануалов";
      case DEPOSIT:
        return "продаже депозита";
      case OTHERS:
        return "продаже другого товара | услуги";
      default:
        return "./";
    }
  };

  const categoryName = useMemo(() => {
    return getCategoryName(intl, deal?.category.name);
  }, [intl, deal?.category.name]);

  return (
    <>
      <div className="col-md-12 row align-items-center justify-content-between mb-6">
        <div className="col-md-8 ms-2 d-flex align-items-center">
          <IconsCard name={categoryName} style={"fs-4x me-5"} />
          {deal?.seller?.uuid === currentUser?.uuid ? (
            <h1>Мое предложение о {getHeading(categoryName)}</h1>
          ) : (
            <h1>Предложение о {getHeading(categoryName)}</h1>
          )}
        </div>
        <button
          className="mt-4 mt-md-0 col-md-2 btn btn-sm btn-primary"
          onClick={() => {
            navigate(-1);
          }}
        >
          Назад
        </button>
      </div>
      <div className="card-body border-top row pb-0 mx-5"></div>

      <div className="d-flex flex-wrap flex-sm-nowrap  align-items-center mb-lg-12 mb-6 position-relative">
        <div className="flex-grow-1 ">
          <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
            <div className="d-flex flex-column">
              <div className="d-flex flex-wrap">
                <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-4 mb-sm-0">
                  <div className="fs-6 text-gray-800 fw-bold">
                    {formatIsoToDate(deal?.created)}
                  </div>
                  <div className="fw-semibold text-gray-500">
                    Дата создания{" "}
                  </div>
                </div>

                {history?.state && history?.state !== "new" && (
                  <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-4 mb-sm-0">
                    <div className="fs-6 text-gray-800 fw-bold">
                      {formatIsoToDate(deal?.expired)}
                    </div>
                    <div className="fw-semibold text-gray-500">Истекает</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {isHistoryLoading && (
        <div className="spinner-border spinner-border-sm align-middle ms-2 mb-12"></div>
      )}
      {history && (
        <div className="d-flex mb-12 align-items-center">
          <div className="me-5">
            <BadgeStatusDeal status={history.state} />
          </div>
          <span className="fw-semibold fs-6 fs-md-4 mb-1 me-5 ">-</span>
          <span className="fw-bold fs-7 fs-md-4 mb-1">{history.comment}</span>
        </div>
      )}

      {deal && <BadgeTimeStatusDeal deal={deal} />}

      <div className="card-body border-top pb-0 pt-7"></div>

      <div className="card-body border-top pb-0"></div>
    </>
  );
};

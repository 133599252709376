import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import clsx from "clsx";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ShowError } from "../../../../errors/components/ShowError";

import { convertCentsToDollars } from "../../../../../utils";
import { Deal } from "../../../core/_models";
import { useGetFeeDispute, useStartDispute } from "../../../core/_requests";

interface Props {
  deal: Deal;
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const initialValuesCreate = {
  description: "",
};

export interface CreateSchema {
  description: string;
}

export const CreateDisputeModalBody: FC<Props> = ({ deal, setIsOpenModal }) => {
  const intl = useIntl();

  const [error, setError] = useState<any | null>(null);

  const [createForAdd] = useState<CreateSchema>(initialValuesCreate);

  const editSchema = Yup.object().shape({
    description: Yup.string()
      .trim()
      .min(3, intl.formatMessage({ id: "AUTH.VALIDATION.MIN_LENGTH_3" }))
      .max(300, intl.formatMessage({ id: "AUTH.VALIDATION.MAX_LENGTH_300" }))
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD" })),
  });

  const {
    mutate: getFee,
    data: feeData,
    isLoading: isFeeDataLoading,
    error: feeDataError,
  } = useGetFeeDispute();

  const {
    mutate: startDispute,
    data: startDisputeData,
    isLoading: isStartDisputeLoading,
    error: startDisputeError,
  } = useStartDispute();

  useEffect(() => {
    if (deal?.category.id) {
      getFee({
        categoryId: deal?.category.id,
        amount: { amount: deal?.amount },
      });
    }
  }, [deal?.category.id]);

  useEffect(() => {
    if (feeDataError || startDisputeError) {
      setError(feeDataError || startDisputeError);
    }
  }, [feeDataError, startDisputeError]);

  const formik = useFormik({
    initialValues: createForAdd,
    validationSchema: editSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      setError(null);

      try {
        startDispute(
          { uuid: deal.uuid, desc: values.description },
          {
            onSuccess: () => {
              window.location.reload();
            },
          }
        );
      } catch (ex) {
        console.error(ex);
      } finally {
        setSubmitting(true);
      }
    },
  });

  return (
    <div className="py-5">
      {feeData < deal?.amount ? (
        <form
          id="kt_modal_add_user_form"
          className="form"
          onSubmit={formik.handleSubmit}
          noValidate
        >
          <div className="mb-14 fs-md-4 fs-7 fw-bold">
            Опишите причину спора. Её увидит вторая сторона сделки и арбитр:{" "}
            <br />
            <div className="fv-row my-5">
              <textarea
                {...formik.getFieldProps("description")}
                className={clsx(
                  "form-control fs-8 fs-md-6  form-control-solid",
                  {
                    "is-invalid":
                      formik.touched.description && formik.errors.description,
                  },
                  {
                    "is-valid":
                      formik.touched.description && !formik.errors.description,
                  }
                )}
                rows={3}
                placeholder={"Опишите проблему"}
                name="description"
                autoComplete="off"
                disabled={formik.isSubmitting || feeData === undefined}
              ></textarea>

              {formik.touched.description && formik.errors.description && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block fs-8 fs-md-6">
                    <span role="alert">{formik.errors.description}</span>
                  </div>
                </div>
              )}
            </div>
            При отрытии спора с Вашего счета будет списана комиссия сервиса за
            арбитраж{" "}
            {isFeeDataLoading && (
              <span
                className="spinner-border spinner-border-sm me-4"
                role="status"
                aria-hidden="true"
              ></span>
            )}{" "}
            <strong className="fs-3">
              {feeData && convertCentsToDollars(feeData)} USDT
            </strong>
            . <br />
            Для решения спора вам будет назначен арбитр.
          </div>

          <div className="p-2 d-flex justify-content-between align-items-center">
            <button
              disabled={
                formik.isSubmitting ||
                !formik.isValid ||
                !formik.touched ||
                isStartDisputeLoading
              }
              type="submit"
              data-kt-users-modal-action="submit"
              className="d-block me-5 me-sm-20 btn btn-success btn-sm px-10 px-sm-15 fs-sm-6"
            >
              {(formik.isSubmitting || isStartDisputeLoading) && (
                <span
                  className="spinner-border spinner-border-sm me-4"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              Принять
            </button>

            <button
              type="reset"
              className="d-block btn btn-danger btn-sm px-8 px-sm-15 fs-sm-6"
              onClick={() => {
                setIsOpenModal(false);
              }}
              disabled={formik.isSubmitting || isStartDisputeLoading}
            >
              Отменить
            </button>
          </div>

          {error && (
            <div className=" mx-auto mt-10 my-6">
              <ShowError error={error} />
            </div>
          )}
        </form>
      ) : (
        <>
          <p className="fs-4">
            Обеспечения по сделке недостаточно для оплаты комиссии за арбитраж
          </p>
        </>
      )}
    </div>
  );
};

import { FC } from "react";
import { Toast } from "react-bootstrap";

interface Props {
  showToast: boolean;
  setShowToast: (state: boolean) => void;
  state: string;
}

export const ToastTopLeft: FC<Props> = ({ showToast, setShowToast, state }) => {
  return (
    <div
      aria-live="polite"
      aria-atomic="true"
      style={{
        position: "fixed",
        minHeight: "100px",
        top: "8%",
        right: "2%",
        zIndex: showToast ? 1050 : -20,
      }}
      className="w-250px"
    >
      <Toast
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={3000}
        autohide={true}
        bg={state}
      >
        <div className="d-flex flex-center p-3 pt-5">
          {/* <KTIcon iconName="check" className="fs-1 text-white me-2 mb-2" /> */}
          <i className=" fs-1 text-white me-2 mb-2 ki-duotone ki- check"></i>
          <h5 className="text-white ">
            {state === "success" ? "Успешно" : "Что-то пошло не так"}
          </h5>
        </div>
      </Toast>
    </div>
  );
};

import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import { format } from "date-fns";

import { useAuth } from "../../auth";
import {
  useAcceptDeal,
  useCancelDeal,
  useCompleteDeal,
  useConfirmDeal,
  useExpirationDeal,
  useGetDeal,
  useGetDealHistory,
  useGetDispute,
} from "../core/_requests";
import { convertCentsToDollars, convertDollarsToCents } from "../../../utils";
import { Deal } from "../core/_models";
import { LoadingRingCard } from "../../components/LoadingRingCard";
import { BadgeReadyCompleteFool } from "./components/BadgeReadyCompleteFool";
import { BadgeReadyComplete } from "./components/BadgeReadyСomplete";
import { ShowError } from "../../errors/components/ShowError";
import { ModalLayout } from "../../../../_metronic/partials/modals/ModalLayout";
import { ToastTopLeft } from "../../components/ToastTopLeft";
import { ShareDealModal } from "./components/share-deal-modal/ShareDealModal";
import { DealDataCardBody } from "./components/DealDataCardBody";
import { DealDataCardHeader } from "./components/DealDataCardHeader";
import { CreateDisputeModal } from "./components/create-dispute-modal/CreateDisputeModal";
import { DisputeData } from "../dispute-management/core/_models";

export function DealCard() {
  const navigate = useNavigate();
  const { currentUser, isModerator } = useAuth();

  const { uuid } = useParams();

  const [modalData, setModalData] = useState<any | null>(null);
  const [isOpenShareModal, setIsOpenShareModal] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);

  const [deal, setDeal] = useState<Deal | undefined>(undefined);
  const [description, setDescription] = useState<any>(undefined);

  const [halfBuyerAmount, setHalfBuyerAmount] = useState(0);
  const [halfSellerAmount, setHalfSellerAmount] = useState(0);
  const [halfErrorInput, setHalfErrorInput] = useState<any>(null);
  const [hourSellerAmount, setHourSellerAmount] = useState(0);
  const [hourErrorInput, setHourErrorInput] = useState<any>(null);
  const [newExpirationDate, setNewExpirationDate] = useState<Date | null>(null);

  const [isOpenDisputeModal, setIsOpenDisputeModal] = useState(false);

  const [error, setError] = useState<any | null>(null);

  const nowTime = new Date().toISOString();

  const isExpiredComplete = useMemo(() => {
    return deal && new Date(deal.expired) < new Date();
  }, [deal]);

  const isDisputeOpenAllowed = useMemo(() => {
    return isExpiredComplete === false && deal?.dispute === false;
  }, [isExpiredComplete, deal]);

  const prevDealDataRef = useRef<any>(null);

  const [disputeDataCurrent, setDisputeDataCurrent] =
    useState<DisputeData | null>(null);

  const {
    mutate: getDeal,
    data: dealData,
    isLoading: isDataDealLoading,
    error: dealDataError,
  } = useGetDeal();

  const { mutate: getHistory, data: history } = useGetDealHistory();

  const {
    mutate: cancelDeal,
    data: cancelDealData,
    isLoading: isCancelDealDataLoading,
    error: cancelDealDataError,
  } = useCancelDeal();

  const {
    mutate: completeDeal,
    data: completeDealData,
    isLoading: isCompleteDealDataLoading,
    error: completeDealDataError,
  } = useCompleteDeal();

  const {
    mutate: expirationDeal,
    data: expirationDealData,
    isLoading: isExpirationDealDataLoading,
    error: expirationDealDataError,
  } = useExpirationDeal();

  const {
    mutate: confirmDeal,
    data: confirmDealData,
    isLoading: isConfirmDealDataLoading,
    error: confirmDealDataError,
  } = useConfirmDeal();

  const { mutate: getDispute, data: disputeData } = useGetDispute();

  const setModalExpirationDeal = () => {
    setModalData({
      title: "Продлить сделку",
      desc: `На сколько часов необходимо продлить сделку?`,
      action: null,
      isLoading: isExpirationDealDataLoading,
      isExpiration: true,
      btnTrueName: "Подтвердить",
      btnFalseName: "Отменить",
    });
  };

  const setModalFoolConfirmDeal = () => {
    setModalData({
      title: "Завершить сделку с полной оплатой",
      desc: `Вы действительно хотите успешно завершить сделку? Это действие отменить нельзя. Деньги (${convertCentsToDollars(
        Number(deal?.amount)
      )} USDT) будут отправлены продавцу сразу после подтверждения обеими сторонами.`,
      action: () =>
        confirmDeal({
          uuid: uuid,
          money: {
            seller: Number(deal?.amount),
            buyer: 0,
          },
        }),
      isLoading: isConfirmDealDataLoading,
      btnTrueName: "Подтвердить",
      btnFalseName: "Отменить",
    });
  };

  const setModalCompleteDeal = () => {
    setModalData({
      title: "Закрыть сделку",
      desc: "Предложение будет закрыто. Вы уверены что хотите закрыть сделку?",
      action: () => completeDeal(uuid as string),
      isLoading: isCancelDealDataLoading,
      btnTrueName: "Да",
      btnFalseName: "Нет",
    });
  };

  const setModalHalfConfirmDeal = () => {
    setModalData({
      title: "Вынести решение по сделке",
      desc: null,
      action: null,
      isLoading: isConfirmDealDataLoading,
      isHalf: true,
      btnTrueName: "Подтвердить",
      btnFalseName: "Отменить",
    });
  };

  const setModalDataCancelDeal = () => {
    setModalData({
      title: "Отмена сделки",
      desc: `Действительно хотите отменить сделку? Сумма сделки ${convertCentsToDollars(
        Number(deal?.amount)
      )} USDT будет возвращена покупателю. Комиссия сервиса не возвращается.`,
      action: () =>
        confirmDeal({
          uuid: uuid,
          money: {
            seller: 0,
            buyer: Number(deal?.amount),
          },
        }),
      isLoading: isCancelDealDataLoading,
      btnTrueName: "Подтвердить",
      btnFalseName: "Я передумал",
    });
  };

  useEffect(() => {
    if (
      disputeData &&
      JSON.stringify(disputeData) !== JSON.stringify(disputeDataCurrent)
    ) {
      setDisputeDataCurrent(disputeData);
    }
  }, [disputeData, disputeDataCurrent]);

  useEffect(() => {
    if (!uuid) {
      navigate("/");
    } else {
      setDeal(undefined);
      getDeal(uuid);

      getDeal(uuid, {
        onSuccess: (data) => {
          if (data.dispute) {
            getDispute(uuid);
          }
        },
      });

      const intervalId = setInterval(() => {
        getDeal(uuid, {
          onSuccess: (data) => {
            if (data.dispute) {
              getDispute(uuid);
            }
          },
        });
      }, 15000);

      // Очистка интервала при размонтировании компонента или смене uuid
      return () => clearInterval(intervalId);
    }
    setError(null);
  }, [uuid]);

  useEffect(() => {
    if (
      dealData &&
      JSON.stringify(dealData) !== JSON.stringify(prevDealDataRef.current)
    ) {
      prevDealDataRef.current = dealData;
      setDeal(dealData);
      setHalfSellerAmount(convertCentsToDollars(dealData.amount));
      parseObjectFromString(dealData?.description);
      getHistory(uuid as string);
    }
  }, [dealData]);

  useEffect(() => {
    if (
      dealDataError ||
      confirmDealDataError ||
      cancelDealDataError ||
      completeDealDataError ||
      expirationDealDataError
    ) {
      setError(
        dealDataError ||
          confirmDealDataError ||
          cancelDealDataError ||
          completeDealDataError ||
          expirationDealDataError
      );
      setShowErrorToast(true);
    }
  }, [
    dealDataError,
    confirmDealDataError,
    cancelDealDataError,
    completeDealDataError,
    expirationDealDataError,
  ]);

  useEffect(() => {
    let dealData = null;

    if (cancelDealData) {
      dealData = cancelDealData;
    } else if (confirmDealData) {
      dealData = confirmDealData;
    } else if (completeDealData) {
      dealData = completeDealData;
    } else if (expirationDealData) {
      dealData = expirationDealData;
    } else {
      return;
    }

    if (dealData) {
      setShowSuccessToast(true);
      setDeal(dealData);
      parseObjectFromString(dealData.description);
      getHistory(uuid as string);
    }
  }, [cancelDealData, confirmDealData, completeDealData, expirationDealData]);

  function parseObjectFromString(str: string) {
    const obj: any = {};

    const keyValues = str.split(" /n/ ");

    keyValues.forEach((kv) => {
      let indexOfFirstColon = kv.indexOf(":");
      if (indexOfFirstColon === -1) {
        obj[kv] = "-";
      } else {
        let key = kv.substring(0, indexOfFirstColon);
        let value = kv.substring(indexOfFirstColon + 1).trim();
        obj[key] = value === "undefined" || value === "" ? "-" : value;
      }
    });

    setDescription(obj);
  }

  const HalfAmountModal = () => {
    const halfCalculateAmount = (
      event: React.ChangeEvent<HTMLInputElement>
    ): void => {
      let inputAmount: number = Number(event.target.value);
      setHalfErrorInput(null);

      const isValidFormat = /^\d+$/;

      if (!isValidFormat.test(String(inputAmount))) {
        setHalfErrorInput("Нельзя вводить больше чем 2 знака после запятой");
        return;
      }

      if (dealData) {
        if (inputAmount < 0) {
          inputAmount = 0;
          setHalfErrorInput("Нельзя ввести меньше 0");
          return;
        } else if (inputAmount > convertCentsToDollars(dealData.amount)) {
          inputAmount = convertCentsToDollars(dealData.amount);
          setHalfErrorInput("Нельзя ввести больше чем есть");
          return;
        }

        const remainder: number =
          convertCentsToDollars(dealData.amount) - inputAmount;

        setHalfSellerAmount(Number(inputAmount.toFixed(2)));
        setHalfBuyerAmount(Number(remainder.toFixed(2)));
      }
    };

    return (
      <>
        <div className="mb-10 fs-md-4 fs-7 fw-bold text-center">
          {`Разделить обеспечение сделки ${convertCentsToDollars(
            Number(deal?.amount)
          )} USDT:`}
          <br />
          {`Оплата продавцу: ${halfSellerAmount} USDT.`}
          <br />
          {`Возврат покупателю ${halfBuyerAmount} USDT.`}
        </div>

        <div className="row mb-12 mx-10">
          <label className="col-lg-4 col-form-label fw-bold fs-6">
            Оплата продавцу
          </label>

          <div className="col-lg-8 fv-row">
            <input
              placeholder="USDT"
              value={halfSellerAmount.toString()}
              onChange={halfCalculateAmount}
              step="1"
              type="number"
              name="halfSellerAmount"
              className={clsx(
                "form-control form-control-lg form-control-solid ",
                {
                  "is-invalid": halfErrorInput,
                },
                {
                  "is-valid": !halfErrorInput,
                }
              )}
              autoComplete="off"
            />
            {halfErrorInput && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{halfErrorInput}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  };

  function ExpirationModal() {
    if (deal === undefined) return "ошибка!";

    const expired = deal?.expired;
    const expirationDate = new Date(expired);

    const hourCalculateAmount = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const input = event.target.value;
      const hours = parseInt(input, 10);

      if (isNaN(hours)) {
        setHourErrorInput("Введите количество часов");
        setHourSellerAmount(hours);
        setNewExpirationDate(null);
      } else if (hours < 0) {
        setHourErrorInput("Нельзя писать минусовое значение");
      } else {
        setHourErrorInput(null);
        setHourSellerAmount(hours);
        const newDate = new Date(
          expirationDate.getTime() + hours * 60 * 60 * 1000
        );
        setNewExpirationDate(newDate);
      }
    };

    const formattedExpirationDate = newExpirationDate
      ? format(newExpirationDate, "dd.MM.yyyy HH:mm")
      : format(expirationDate, "dd.MM.yyyy HH:mm");

    return (
      <>
        <div className="mb-10 fs-md-4 fs-7 fw-bold text-center">
          {`Сделка истечет: ${formattedExpirationDate}`}
        </div>
        <div className="m-auto w-75 mb-12">
          <label className=" col-form-label fw-bold fs-6">Время:</label>
          <div className="fv-row">
            <input
              placeholder="(ч.)"
              value={hourSellerAmount.toString()}
              onChange={hourCalculateAmount}
              step="1"
              type="number"
              name="halfSellerAmount"
              className={clsx(
                "form-control form-control-lg form-control-solid",
                {
                  "is-invalid": hourErrorInput,
                },
                {
                  "is-valid": !hourErrorInput,
                }
              )}
              autoComplete="off"
            />
            {hourErrorInput && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{hourErrorInput}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="card card-body">
      {isDataDealLoading && !deal ? (
        <LoadingRingCard />
      ) : deal?.seller?.uuid === currentUser?.uuid ||
        deal?.buyer?.uuid === currentUser?.uuid ? (
        <>
          <div className="bg-light-danger rounded border-danger border border-dashed w-100 d-block px-md-10 my-15">
            <div
              className="mx-5 d-flex flex-center collapsible collapsed py-3 toggle mb-0"
              data-bs-toggle="collapse"
            >
              <div className="btn btn-sm btn-icon mw-20px btn-active-color-primary me-2 me-sm-8">
                <i className="ki-duotone ki-information fs-3x text-danger me-4">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                </i>
              </div>

              <div className="text-gray-700 fw-bold fs-6 fs-sm-4  mb-0">
                Свою сделку модерировать запрещено
              </div>
            </div>
          </div>
        </>
      ) : deal ? (
        <div className="p-5 p-md-10">
          <DealDataCardHeader deal={deal} />
          <DealDataCardBody deal={deal} description={description} />
          {history?.state === "completed" ? (
            <BadgeReadyCompleteFool
              deal={deal}
              resolution={disputeDataCurrent?.resolution}
            />
          ) : (
            <>
              {/* Для модератора  */}
              {deal?.seller?.uuid !== currentUser?.uuid &&
                history?.state === "running" && (
                  <div className="mt-15  d-flex justify-content-center align-items-center flex-column ">
                    <BadgeReadyComplete deal={deal} />

                    {isModerator && (
                      <>
                        {deal.dispute || nowTime < deal.expired ? (
                          <button
                            type="button"
                            disabled={
                              isConfirmDealDataLoading ||
                              isCancelDealDataLoading
                            }
                            className="mt-5 w-100 w-sm-75 w-md-50 d-block btn btn-warning px-8 px-sm-10 mb-5"
                            onClick={() => {
                              setModalExpirationDeal();
                            }}
                          >
                            {isCancelDealDataLoading && (
                              <span
                                className="spinner-border spinner-border-sm me-4"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            )}
                            Продлить сделку
                          </button>
                        ) : deal.dispute === false && isExpiredComplete ? (
                          <>
                            <button
                              type="button"
                              disabled={
                                isConfirmDealDataLoading ||
                                isCancelDealDataLoading
                              }
                              className="mt-5 w-100 w-sm-75 w-md-50 d-block btn btn-warning px-8 px-sm-10 mb-5"
                              onClick={() => {
                                setModalExpirationDeal();
                              }}
                            >
                              {isCancelDealDataLoading && (
                                <span
                                  className="spinner-border spinner-border-sm me-4"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              )}
                              Продлить сделку
                            </button>

                            <button
                              type="button"
                              disabled={isCancelDealDataLoading}
                              className="w-100 w-sm-75 w-md-50 d-block btn btn-danger px-8 px-sm-10 mb-5"
                              onClick={() => {
                                setIsOpenDisputeModal(true);
                              }}
                            >
                              {isCancelDealDataLoading && (
                                <span
                                  className="spinner-border spinner-border-sm me-4"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              )}
                              Открыть спор по сделке
                            </button>

                            <button
                              type="button"
                              disabled={isCancelDealDataLoading}
                              className="w-100 w-sm-75 w-md-50 d-block btn btn-primary px-8 px-sm-10 mb-5"
                              onClick={() => {
                                setModalHalfConfirmDeal();
                              }}
                            >
                              {isCancelDealDataLoading && (
                                <span
                                  className="spinner-border spinner-border-sm me-4"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              )}
                              Вынести решение по сделке
                            </button>
                          </>
                        ) : (
                          <>
                            {" "}
                            <button
                              type="button"
                              disabled={
                                isConfirmDealDataLoading ||
                                isCancelDealDataLoading
                              }
                              className="mt-5 w-100 w-sm-75 w-md-50 d-block btn btn-success px-8 px-sm-10 mb-5"
                              onClick={() => {
                                setModalFoolConfirmDeal();
                              }}
                            >
                              {isCancelDealDataLoading && (
                                <span
                                  className="spinner-border spinner-border-sm me-4"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              )}
                              Завершить сделку с полной оплатой
                            </button>
                            <button
                              type="button"
                              disabled={isCancelDealDataLoading}
                              className="w-100 w-sm-75 w-md-50 d-block btn btn-primary px-8 px-sm-10 mb-5 "
                              onClick={() => {
                                setModalHalfConfirmDeal();
                              }}
                            >
                              {isCancelDealDataLoading && (
                                <span
                                  className="spinner-border spinner-border-sm me-4"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              )}
                              Завершить сделку с частичной оплатой
                            </button>
                            <button
                              type="button"
                              disabled={isCompleteDealDataLoading}
                              className="w-100 w-sm-75 w-md-50 d-block btn btn-warning px-8 px-sm-10 mb-5"
                              onClick={() => {
                                setModalCompleteDeal();
                              }}
                            >
                              {isCompleteDealDataLoading && (
                                <span
                                  className="spinner-border spinner-border-sm me-4"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              )}
                              Закрыть сделку
                            </button>
                            <button
                              type="button"
                              disabled={isCancelDealDataLoading}
                              className="w-100 w-sm-75 w-md-50 d-block btn btn-danger px-8 px-sm-10 "
                              onClick={() => {
                                setModalDataCancelDeal();
                              }}
                            >
                              {isCancelDealDataLoading && (
                                <span
                                  className="spinner-border spinner-border-sm me-4"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              )}
                              Отменить сделку
                            </button>
                          </>
                        )}
                      </>
                    )}
                  </div>
                )}
            </>
          )}
        </div>
      ) : null}

      {error && (
        <div className="w-lg-50 mx-auto mt-5 mt-10">
          <ShowError error={error} />
        </div>
      )}

      {isOpenDisputeModal && deal && (
        <CreateDisputeModal
          isOpenModal={isOpenDisputeModal}
          setIsOpenModal={setIsOpenDisputeModal}
          deal={deal}
        />
      )}

      <ModalLayout
        title={modalData?.title}
        show={Boolean(modalData)}
        handleClose={() => setModalData(null)}
      >
        {modalData?.desc && (
          <div className="mb-10 fs-md-4 fs-7 fw-bold text-center">
            {modalData?.desc}
          </div>
        )}

        {modalData?.isHalf && HalfAmountModal()}

        {modalData?.isExpiration && ExpirationModal()}

        <div className="p-2 d-flex justify-content-center align-items-center">
          <button
            type="button"
            disabled={modalData?.isLoading || hourErrorInput || halfErrorInput}
            className="d-block me-5 me-sm-20 btn btn-success px-10 px-sm-15"
            onClick={() => {
              if (uuid) {
                modalData?.isHalf
                  ? confirmDeal(
                      {
                        uuid: uuid,
                        money: {
                          seller: convertDollarsToCents(halfSellerAmount),
                          buyer: convertDollarsToCents(halfBuyerAmount),
                        },
                      },
                      {
                        onSuccess: () => {
                          completeDeal(uuid as string);
                        },
                      }
                    )
                  : modalData?.isExpiration
                  ? expirationDeal(
                      {
                        uuid: uuid,
                        expiration: {
                          expired_date: newExpirationDate,
                        },
                      },
                      {
                        onSuccess: () => {
                          window.location.reload();
                        },
                      }
                    )
                  : modalData?.action();
              }

              setModalData(null);
            }}
          >
            {modalData?.isLoading && (
              <span
                className="spinner-border spinner-border-sm me-4"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            {modalData?.btnTrueName}
          </button>

          <button
            type="button"
            className="d-block btn btn-danger px-8 px-sm-15"
            onClick={() => {
              setModalData(null);
            }}
          >
            {modalData?.btnFalseName}
          </button>
        </div>
      </ModalLayout>

      {isOpenShareModal && (
        <ShareDealModal
          isOpenModal={isOpenShareModal}
          setIsOpenModal={setIsOpenShareModal}
          uuid={uuid as string}
        />
      )}

      <ToastTopLeft
        showToast={showSuccessToast}
        setShowToast={setShowSuccessToast}
        state="success"
      />
      <ToastTopLeft
        showToast={showErrorToast}
        setShowToast={setShowErrorToast}
        state="danger"
      />
    </div>
  );
}

import React, { useState } from "react";
import { FC } from "react";
import { ConfirmItem, Deal } from "../../core/_models";
import { convertCentsToDollars } from "../../../../utils";

interface Props {
  deal: Deal;
}

export const BadgeTimeStatusDeal: FC<Props> = ({ deal }) => {
  let statusMessage = "тут пишем статус";

  const now = new Date().toISOString();

  if (deal.state === "running") {
    if (deal.dispute === true) {
      // Проверка на открытый спор
      statusMessage = "Открыт спор";
    } else if (now > deal.expired) {
      // Проверка прошло ли время экспирации
      statusMessage =
        "Время экспирации прошло, модератор может завершить сделку единолично после голосования.";
    } else {
      // Логика для определения кворума
      // Этот пример не включает реализацию определения кворума, так как ваши данные не содержат информации о голосовании
      const quorumReached = false; // нужно заменить на реальную проверку кворума

      if (quorumReached) {
        statusMessage = "Время экспирации НЕ прошло, кворум достигнут.";
      } else {
        statusMessage = "Время экспирации НЕ прошло, кворум не достигнут.";
      }
    }
  } else {
    statusMessage = "Сделка в модерации не нуждается";
  }

  return (
    <>
      {deal && (
        <div className="bg-light-warning rounded border-warning border border-dashed w-100 d-block px-md-10 mb-10">
          <div
            className="mx-5 d-flex flex-center collapsible collapsed py-3 toggle mb-0"
            data-bs-toggle="collapse"
          >
            <div className="btn btn-sm btn-icon mw-20px btn-active-color-primary me-2 me-sm-8">
              <i className="ki-duotone ki-information fs-3x text-warning me-4">
                <span className="path1"></span>
                <span className="path2"></span>
                <span className="path3"></span>
              </i>
            </div>

            <div className="text-gray-700 fw-bold fs-6 fs-sm-4  mb-0 ">
              {statusMessage}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
